import { rbcColors } from '@internal/shared';
import { Box, makeStyles } from '@material-ui/core';
import { TextParagraph, TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: rbcColors.greyLightTint1,
      position: 'relative',
      padding: theme.spacing(3),
      textAlign: 'start',
      color: rbcColors.black,
      border: '1px solid #6f6f6f',
      height: 200,
      [theme.breakpoints.down('md')]: {
        backgroundSize: 'cover',
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  };
});

export type LearnDetails = {
  title: string;
  description: string;
  link: string;
};

type Props = {
  item: LearnDetails;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const LearnCard = ({ item, className = '' }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box
      className={`${classes.root} ${className}`}
      tabIndex={0}
      onClick={() => navigate(`/${item.link}`)}
      aria-label={item.title}
    >
      <TextStyle
        kind="heading"
        size="md"
        elementType="h3"
        className={classes.title}
      >
        {item.title}
      </TextStyle>
      <TextParagraph>{item.description}</TextParagraph>
    </Box>
  );
};

export default LearnCard;
