import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { rbcColors } from '@internal/shared';
import { styled, Box, Typography } from '@material-ui/core';
import {
  TextParagraph,
  RBCIcon,
  MenuItem,
  CTA,
  MenuGroup,
  Loader,
  BannerNotice,
  TextHeading,
} from '@rig/rw-core-components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import SwaggerUI from 'swagger-ui-react';
import payWithPointsImg from './assets/payWithPoints.png';
import databaseImg from './assets/database.png';
import systemImg from './assets/system.png';
import mdfile from './assets/payWithPoints.md';
import ReactMarkdown from 'react-markdown';
import useAsync from 'react-use/lib/useAsync';

const StyledParagraph = styled(TextParagraph)(() => ({
  fontWeight: 400,
  fontSize: '14px',
}));

const StyledHeader = styled(TextParagraph)(() => ({
  fontSize: '12px',
}));

const StyledSideNavContainer = styled(Box)(() => ({
  borderTop: '1px solid #E0E0E0',
  borderBottom: '1px solid #E0E0E0',
  height: 'fit-content',
  width: '268px',
  '& .rbc-menu-group-heading': {
    padding: 0,
  },
}));

const StyledImage = styled('img')(() => ({
  width: '100%',
}));

const StyledReactMarkdown = styled(ReactMarkdown)(() => ({
  '& h1': {
    fontSize: '28px',
    marginBottom: '32px',
    marginTop: '32px',
  },
  '& h2': {
    fontWeight: 300,
    fontSize: '24px',
    marginBottom: '32px',
    marginTop: '32px',
  },
  '& h3': {
    fontSize: '20px',
    marginBottom: '24px',
    marginTop: '24px',
  },
  '& ol': {
    listStyle: 'auto',
    marginLeft: '16px',
  },
  '& p': {
    fontSize: '16px',
  },
  '& pre': {
    backgroundColor: 'rgb(40, 44, 52)',
    color: 'white',
    padding: '16px',
    marginBottom: '16px',
    marginTop: '16px',
  },
  '& p code': {
    backgroundColor: rbcColors.greyLightTint2,
    padding: '2px 4px',
  },
}));

const API_METADATA = [
  { label: 'Line of Business', value: 'Hack The North' },
  { label: 'Version', value: 'v1' },
  { label: 'Updated', value: 'Aug 30th, 2024' },
  { label: 'Downloads', value: 'N/A' },
];

const StyledCta = styled(CTA)(() => ({
  display: 'flex',
  alignItems: 'center',
  gridGap: '8px',
  width: 'fit-content',
  textDecoration: 'none',
}));

const HtnEntityDefinition = ({ definition }: { definition: string }) => {
  return (
    <SwaggerUI
      // tryItOutEnabled={false}
      // supportedSubmitMethods={[]}
      spec={definition}
    />
  );
};

const HtnEntityOverview = ({ mdContent }: { mdContent: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: '24px',
      }}
    >
      <Typography
        variant="h2"
        style={{
          fontWeight: 400,
          fontSize: '24px',
        }}
      >
        Overview
      </Typography>
      <StyledImage src={payWithPointsImg} alt="pay with points" />
      <StyledReactMarkdown>{mdContent}</StyledReactMarkdown>
      <TextHeading headingLevel="h2" useCase="Notice Heading">
        <strong>Architecture 🏠</strong>
      </TextHeading>
      <StyledImage src={systemImg} alt="system" />
      <TextHeading headingLevel="h2" useCase="Notice Heading">
        <strong>Database Schema</strong>
      </TextHeading>
      <StyledImage src={databaseImg} alt="database" />
    </Box>
  );
};

const HtnEntity = () => {
  const catalogApi = useApi(catalogApiRef);
  const [selectedPage, setSelectedPage] = useState('overview');
  const navigate = useNavigate();
  const {
    loading: entityLoading,
    error: entityError,
    value: entity,
  } = useAsync(() =>
    catalogApi.getEntityByRef({
      name: 'pay-with-pretend-points-api',
      kind: 'Component',
      namespace: 'default',
    }),
  );

  const {
    value: file,
    error: fileError,
    loading: fileLoading,
  } = useAsync(() => fetch(mdfile).then(res => res.text()));

  if (entityLoading || fileLoading) {
    return <Loader dataSize="large" kind="full" loadingMessage="Loading" />;
  }

  if (entityError) {
    return (
      <BannerNotice
        kind="danger"
        heading="Error"
        message="Not able to get entity"
      />
    );
  }

  if (fileError) {
    return (
      <BannerNotice
        kind="danger"
        heading="Error"
        message="Not able to get md file"
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: '40px',
      }}
    >
      <StyledCta
        priority="tertiary"
        onClick={() => {
          navigate('../');
        }}
      >
        <RBCIcon
          decorative
          name="chevron-left"
          size="s"
          srLabel="back"
          useSprites
        />
        Go back
      </StyledCta>
      <Box>
        <Typography
          variant="h2"
          style={{
            fontWeight: 400,
            fontSize: '28px',
          }}
        >
          Pay With Pretend Points API
        </Typography>
        <TextParagraph>
          This is a very cool Pay With Pretend Points API. This allows the users
          to pay with pretend points.
        </TextParagraph>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          border: '1px solid #E0E0E0',
          borderRadius: '8px',
          bgcolor: rbcColors.greyLightTint1,
        }}
      >
        {API_METADATA.map(({ label, value }, index) => (
          <Box
            key={label}
            sx={{
              padding: '16px 20px',
              width: '25%',
              borderRight: index !== 3 ? '1px solid #E0E0E0' : 'none',
            }}
          >
            <StyledHeader>{label}</StyledHeader>
            <StyledParagraph>{value}</StyledParagraph>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gridGap: '40px',
        }}
      >
        <StyledSideNavContainer>
          <MenuGroup label="">
            <MenuItem
              itemId="a"
              onClick={() => {
                setSelectedPage('overview');
              }}
              target="none"
              selected={selectedPage === 'overview'}
              text="Overview"
            />
            <MenuItem
              itemId="b"
              onClick={() => {
                setSelectedPage('definition');
              }}
              selected={selectedPage === 'definition'}
              target="none"
              text="Definition"
            />
          </MenuGroup>
        </StyledSideNavContainer>
        <Box width="100%">
          {selectedPage === 'definition' ? (
            <HtnEntityDefinition
              definition={(entity?.spec?.definition as string) ?? ''}
            />
          ) : (
            <HtnEntityOverview mdContent={file ?? ''} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HtnEntity;
