import React from 'react';
import { Route, Routes } from 'react-router';
import 'swagger-ui-react/swagger-ui.css';
import HtnEntity from './HtnEntity';
import Layout from './Layout';
import HtnCatalog from './HtnCatalog';

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HtnCatalog />} />
        <Route path="entity" element={<HtnEntity />} />
      </Route>
    </Routes>
  );
}
