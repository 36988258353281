import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const docRenderPlugin = createPlugin({
  id: 'doc-render',
  routes: {
    root: rootRouteRef,
  },
});

export const DocRenderPage = docRenderPlugin.provide(
  createRoutableExtension({
    name: 'DocRenderPage',
    component: () => import('./pages/DocRender').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
