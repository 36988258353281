import { createApiRef } from '@backstage/core-plugin-api';
import { ScanDTO1 } from '@internal/backstage-plugin-chorus-av-data-module-common';

export interface H2hTestApi {
  validate: (file: FormData) => Promise<string>;
  scan: (file: FormData) => Promise<ScanDTO1>;
}

export const H2hTestApiRef = createApiRef<H2hTestApi>({
  id: 'plugin.h2h-test-api.service',
});
