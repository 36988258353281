import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  analyticsApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import {
  TaplyticsApiRef,
  TaplyticsImpl,
} from '@internal/backstage-plugin-taplytics-react';
import { RbcGoogleAnalytics4 } from '@internal/plugin-rbc-google-analytics';
import { UrlPatternDiscovery } from '@backstage/core-app-api';

// apis
export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: TaplyticsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => TaplyticsImpl.fromConfig(configApi),
  }),
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => RbcGoogleAnalytics4.fromConfig(configApi),
  }),
  createApiFactory({
    api: discoveryApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => {
      // the configApi.getString('environment').toLocaleUpperCase() === 'DEV' part is temperary, remove it when the qat and uat are onboarded with no auth
      return UrlPatternDiscovery.compile(
        `${configApi.getString('backend.baseUrl')}${
          (configApi.getOptionalString('environment')?.toLocaleUpperCase() ??
            '') !== 'LOCAL'
            ? '/secure/EPD0/EXT-DEV-PORTAL-UI-BACKEND/v1'
            : ''
        }/api/{{ pluginId }}`,
      );
    },
  }),
  ScmAuth.createDefaultApiFactory(),
];
