import makeStyles from '@mui/styles/makeStyles';
import { CTA, CTAText } from '@rig/rw-core-components';
import React from 'react';

const useStyles: any = makeStyles(theme => ({
  btnContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  },
  button: {
    padding: theme.spacing(2, 6),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export const ContactUsPageButtons = ({
  onSubmit,
}: {
  onSubmit: (submittedValue: boolean) => void;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.btnContainer}>
      <CTAText onClick={() => {}}>Cancel</CTAText>
      <CTA
        priority="primary"
        size="small"
        className={classes.button}
        onClick={() => onSubmit(true)}
      >
        Submit
      </CTA>
    </div>
  );
};
export default ContactUsPageButtons;
