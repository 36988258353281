import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { H2hTestApiClient, H2hTestApiRef } from './api';

export const h2HTestPlugin = createPlugin({
  id: 'h2h-test',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: H2hTestApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) =>
        new H2hTestApiClient({ discoveryApi }),
    }),
  ]
});

export const H2HTestPage = h2HTestPlugin.provide(
  createRoutableExtension({
    name: 'H2HTestPage',
    component: () =>
      import('./components/H2hTestPlugin').then(m => m.H2hTestPlugin),
    mountPoint: rootRouteRef,
  }),
);
