import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { RBCIconButton } from '@rig/rw-core-components';
import React from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { rbcColors, uscmPalette } from '../../themes';

type DragAndDropComponentProps = {
  uploadBtnText: string;
  fileMaxLimitLabel: string;
  fileTypeTextLabel: string;
  uploadFileWarning: string;
  disclaimerLabel?: string;
  handleFileUpload: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
};

const useStyles = makeStyles({
  dragDropUploadZone: {
    margin: '0 16px',
    border: `2px dashed ${rbcColors.rbcBlueTint1}`,
    width: '98%',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: uscmPalette.background.secondary,
    borderRadius: '4px',
    pointerEvents: 'none',
  },
  dragInActiveState: {
    background: uscmPalette.secondary.main,
  },
  headingLabel: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginTop: '24px',
  },
  dragZoneLabelBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadtBtnIcon: {
    marginTop: '24px',
  },
  helperTextLabel: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: rbcColors.greyTint1,
  },
  helperTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '12px 0 24px',
  },
  uploadtBtnLabel: {
    padding: '0 2px 0 0',
    color: rbcColors.rbcBlueTint1,
    textTransform: 'lowercase',
    fontSize: '16px',
    textDecoration: 'underline',
    pointerEvents: 'auto',
  },
  disclaimerMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disclaimerLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    paddingRight: '4px',
  },
});

const DragAndDropComponent = ({
  uploadBtnText,
  fileMaxLimitLabel,
  fileTypeTextLabel,
  uploadFileWarning,
  disclaimerLabel,
  handleFileUpload,
}: DragAndDropComponentProps) => {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: handleFileUpload,
    noClick: true,
  });

  const classes = useStyles();
  return (
    <Box data-testid="drag-drop-container" role="alert">
      <div {...getRootProps()}>
        <input
          {...getInputProps()}
          aria-label="drag drop container"
          data-testid="uploader"
        />
        {isDragActive ? (
          <Box
            className={`${classes.dragDropUploadZone} ${classes.dragInActiveState} `}
          >
            <Box className={classes.dragZoneLabelBox}>
              <RBCIconButton
                iconPosition="solo"
                icon="upload"
                iconSize="s"
                className={classes.uploadtBtnIcon}
                srLabel="File upload icon"
              />
              <Typography className={classes.headingLabel}>
                Drag and drop or{' '}
                <Button
                  role="button"
                  tabIndex={0}
                  aria-label={uploadBtnText}
                  variant="text"
                  className={classes.uploadtBtnLabel}
                  onClick={open}
                >
                  {uploadBtnText}
                </Button>{' '}
                to upload
              </Typography>
            </Box>
            <Box className={classes.helperTextContainer}>
              <Typography className={classes.helperTextLabel}>
                {fileMaxLimitLabel}
              </Typography>
              <Typography className={classes.helperTextLabel}>
                {fileTypeTextLabel}
              </Typography>
              <Typography className={classes.helperTextLabel}>
                {uploadFileWarning}
              </Typography>
              <Box className={classes.disclaimerMain}>
                <Typography className={classes.disclaimerLabel}>
                  Disclaimer:
                </Typography>
                <Typography className={classes.helperTextLabel}>
                  {disclaimerLabel}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={classes.dragDropUploadZone}>
            <Box className={classes.dragZoneLabelBox}>
              <RBCIconButton
                iconPosition="solo"
                icon="upload"
                iconSize="s"
                className={classes.uploadtBtnIcon}
                srLabel="File upload icon"
              />
              <Typography className={classes.headingLabel}>
                Drag and drop or{' '}
                <Button
                  role="button"
                  variant="text"
                  tabIndex={0}
                  aria-label={uploadBtnText}
                  className={classes.uploadtBtnLabel}
                  onClick={open}
                >
                  {uploadBtnText}
                </Button>{' '}
                to upload
              </Typography>
            </Box>
            <Box className={classes.helperTextContainer}>
              <Typography className={classes.helperTextLabel}>
                {fileMaxLimitLabel}
              </Typography>
              <Typography className={classes.helperTextLabel}>
                {fileTypeTextLabel}
              </Typography>
              <Typography className={classes.helperTextLabel}>
                {uploadFileWarning}
              </Typography>
              <Box className={classes.disclaimerMain}>
                <Typography className={classes.disclaimerLabel}>
                  Disclaimer:
                </Typography>
                <Typography className={classes.helperTextLabel}>
                  {disclaimerLabel}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default DragAndDropComponent;
