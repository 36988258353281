import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { createMediaProviderList } from '@rig/rw-core-components';
import { useEffect } from 'react';

export const loadRigFont = (rigAssetPath: string) => {
  const rbcmsdisplayFontlink = document.createElement('link');
  rbcmsdisplayFontlink.href = `${rigAssetPath}/fonts/rbc/rbc-ms-display/rbcmsdisplay.css`;
  rbcmsdisplayFontlink.rel = 'stylesheet';
  const robotoFontLink = document.createElement('link');
  robotoFontLink.href = `${rigAssetPath}/fonts/rbc/roboto/roboto.css`;
  robotoFontLink.rel = 'stylesheet';
  document.head.appendChild(rbcmsdisplayFontlink);
  document.head.appendChild(robotoFontLink);
};

export const useMediaContext = () => {
  const api = useApi(configApiRef);
  const cdnUrl = api.getString('rigAssetPath');

  useEffect(() => {
    loadRigFont(cdnUrl);
  }, [cdnUrl]);

  return createMediaProviderList(cdnUrl) as any;
};
