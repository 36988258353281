import { Box } from '@material-ui/core';
import makeStyles from '@mui/styles/makeStyles';
import {
  FieldLabel,
  RadioGroup,
  RadioItem,
  RadioLabel,
  TextInput,
  TextStyle,
  useFormInputState,
} from '@rig/rw-core-components';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      gap: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: theme.spacing(4),
      },
    },
    sectionInfo: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    form: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
  };
});

const ContactInformationWidget = ({
  onNameChange,
  onContactRadioChange,
  onContactChange,
}: {
  onNameChange: (value: string) => void;
  onContactRadioChange: (value: string) => void;
  onContactChange: (value: string) => void;
}) => {
  const classes = useStyles();
  const [nameInputState, setNameInputState] = useFormInputState();
  const [methodInputState, setMethodInputState] = useFormInputState();
  const [contactMethodValue, setContactMethodValue] = useState('');

  const handleContactMethodChange = (value: string) => {
    setContactMethodValue(value);
    onContactRadioChange(value);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.sectionInfo}>
        <TextStyle kind="ui" elementType="h2">
          Contact Information
        </TextStyle>
        <TextStyle kind="ui" emphasis="low" elementType="p">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </TextStyle>
      </Box>
      <Box className={classes.form}>
        <Box>
          <FieldLabel fieldLabel="Full Name" required htmlFor="nameInput" />
          <TextInput
            fieldId="nameInput"
            inputState={nameInputState}
            setInputState={setNameInputState}
            valueChange={e => onNameChange(e.target.value)}
            required
          />
        </Box>

        <RadioGroup
          groupTitle="How would you like to be contacted?"
          formId="contactMethod"
          value={contactMethodValue}
          onChange={e => handleContactMethodChange(e.target.value)}
        >
          <RadioItem id="email" name="method" value="Email">
            <RadioLabel>Email</RadioLabel>
          </RadioItem>
          <RadioItem id="phone" name="method" value="Phone">
            <RadioLabel>Phone</RadioLabel>
          </RadioItem>
        </RadioGroup>

        {contactMethodValue && (
          <div data-testid="methodInput">
            <FieldLabel
              fieldLabel={contactMethodValue}
              required
              htmlFor="methodInput"
            />
            <TextInput
              fieldId="methodInput"
              inputState={methodInputState}
              setInputState={setMethodInputState}
              valueChange={e => onContactChange(e.target.value)}
              required
            />
            <TextStyle kind="ui" emphasis="low" size="sm" elementType="p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </TextStyle>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default ContactInformationWidget;
