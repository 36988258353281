import { makeStyles } from '@material-ui/core';
import { CTA, RBCIcon, TextStyle } from '@rig/rw-core-components';
import React from 'react';
import SignUpSectionImage from './SignUpSectionImage';

const useStyles = makeStyles(theme => {
  return {
    section: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(13),
      paddingBottom: theme.spacing(13),
      zIndex: 0,
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'unset',
        paddingBottom: 0,
      },
    },
    titleAndCta: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
    cta: {
      width: 'fit-content',
      display: 'flex',
      gap: theme.spacing(1),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      alignItems: 'baseline',
      [theme.breakpoints.down('xs')]: {
        width: 'unset',
        marginBottom: theme.spacing(7),
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    ctaIcon: {
      position: 'relative',
      top: theme.spacing(0.3),
    },
  };
});

interface Props {}

const SignUpSection = ({}: Props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.section}>
        <div className={classes.titleAndCta}>
          <TextStyle kind="heading" size="md" elementType="h2">
            Start building your APIs
          </TextStyle>
          <CTA priority="primary" size="small" className={classes.cta}>
            Sign Up
            <RBCIcon
              decorative
              name="arrow-right"
              size="s"
              srLabel="arrow-right"
              useSprites
              className={classes.ctaIcon}
            />
          </CTA>
        </div>
        <SignUpSectionImage />
      </div>
    </>
  );
};

export default SignUpSection;
