import { Box, IconButton, Modal, makeStyles } from '@material-ui/core';
import { ParagraphUseCase } from '@rig/common';
import {
  CTA,
  RBCIcon,
  TextParagraph,
  TextStyle,
  useBreakPointService,
} from '@rig/rw-core-components';
import React, { ReactNode } from 'react';
import { uscmPalette } from '../../themes';

const useStyles = makeStyles({
  closeButton: {
    position: 'relative',
    bottom: '24px',
    left: '24px',
    '& .times': {
      fill: uscmPalette.primary.main,
      width: '16px',
      height: '16px',
      position: 'relative',
      bottom: '4px',
    },
  },
  cta: {
    width: '256px',
  },
  ctaMobile: {
    width: '100%',
  },
  wrapper: {
    maxWidth: '812px',
    marginTop: '12%',
    padding: '16px',
    '&:focus': {
      outline: 'none',
    },
  },
  content: {
    display: 'flex',
    gridGap: '24px',
    background: 'white',
    flexDirection: 'column',
    padding: '24px 32px 32px 32px',
  },
});

type AlertModalProps = {
  open: boolean;
  title: string;
  description: string | ReactNode;
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
  onCloseClick: () => void;
};

// TODO: add more kinds when needed
function AlertModal({
  title,
  description,
  primaryButtonLabel,
  secondaryButtonLabel,
  open,
  onPrimaryClick,
  onSecondaryClick,
  onCloseClick,
}: AlertModalProps) {
  const [{ isAtMost, isAtLeast }] = useBreakPointService('sm');
  const classes = useStyles();

  return (
    <Modal
      open={open}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box className={classes.wrapper}>
        <Box
          sx={{
            border: `1px solid ${uscmPalette.border.main}`,
            borderTop: `4px solid ${uscmPalette.status.warning}`,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box className={classes.content}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box display="flex">
                <Box
                  sx={{
                    width: '24px',
                    marginRight: '16px',
                  }}
                >
                  <RBCIcon name="warning-tt" size="md" />
                </Box>
                <h2>
                  <TextStyle kind="heading" size="2xs">
                    {title}
                  </TextStyle>
                </h2>
              </Box>
              <IconButton
                onClick={onCloseClick}
                disableRipple
                className={classes.closeButton}
              >
                <RBCIcon name="times" size="md" />
              </IconButton>
            </Box>

            <TextParagraph useCase={ParagraphUseCase.supportingDescription}>
              {description}
            </TextParagraph>
            <Box
              sx={{
                display: 'flex',
                justifyContent: isAtLeast && 'flex-end',
                alignItems: isAtMost && 'center',
                flexDirection: isAtMost ? 'column-reverse' : 'row',
                gridGap: '16px',
              }}
            >
              <CTA
                className={isAtMost ? classes.ctaMobile : classes.cta}
                priority="secondary"
                onClick={onSecondaryClick}
              >
                {secondaryButtonLabel}
              </CTA>
              <CTA
                className={isAtMost ? classes.ctaMobile : classes.cta}
                priority="primary"
                onClick={onPrimaryClick}
              >
                {primaryButtonLabel}
              </CTA>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default AlertModal;
