import { Card, uscmPalette } from '@internal/shared';
import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  quickAccessContainer: {
    backgroundColor: uscmPalette.primary.light,
    margin: 0,
    padding: '24px 0',
  },
  cardContainer: {
    display: 'flex',
    gap: '2rem',
    justifyContent: 'center',
    padding: '0 24px',
    maxWidth: '1440px',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
}));

const mockData = [
  {
    header: 'Learn about the Sandbox',
    body: 'Use our Sandbox environment to test your API and host-to-host solutions.',
    path: '/rbc-api-catalog',
    buttonLabel: 'Sign Up',
    buttonType: 'primary',
  },
  {
    header: 'RBC Developer Support',
    body: 'Discover how you can onboard your organization, learn about our technologies, and support solutions.',
    path: '/docs/default/component/documentation-hub/support/contact-support/',
    buttonLabel: 'Discover',
    buttonType: 'secondary',
  },
  {
    header: 'Learn the Basics',
    body: 'Want to know more about our APIs and host-to-host solutions? Explore our documentation to learn more.',
    path: '/docs/default/component/documentation-hub/Overview/Learn-The-Basics-Getting-Started/',
    buttonLabel: 'Explore',
    buttonType: 'secondary',
  },
];

const QuickAccess = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Box className={classes.quickAccessContainer}>
      <Grid item className={classes.cardContainer}>
        {mockData.map(data => (
          <Card
            key={data.header}
            onBtnClick={() => navigate(data.path)}
            body={data.body}
            header={data.header}
            buttonPriority={
              data.buttonType === undefined
                ? 'primary'
                : (data.buttonType as any)
            }
            buttonLabel={data.buttonLabel}
            readOnlyBtn={false}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default QuickAccess;
