import { uscmPalette, useGetApiCatalogEntity } from '@internal/shared';
import { Box, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CTAText, TextStyle } from '@rig/rw-core-components';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  paymentsPage: {
    backgroundColor: uscmPalette.primary.light,
  },
  outerContainer: {
    backgroundColor: uscmPalette.primary.light,
    padding: 0,
    width: '1440px',
    margin: 'auto',
  },
  descriptionContainer: {
    backgroundColor: uscmPalette.background.main,
    maxWidth: '1440px',
    margin: '0px auto 0 0',
  },
  headingStyle: {
    fontFamily: 'RBC MS Display',
  },
  overviewContentHead: {
    padding: '24px',
  },
  overviewContentBody: {
    display: 'flex',
    gap: '100px',
  },
  overviewContentLeft: {
    padding: '24px 12px',
    width: '450px',
  },
  overviewTextStyle: {
    color: uscmPalette.text.main,
    fontSize: '16px',
  },
  overviewTextBody: {
    marginTop: '8px',
    minWidth: '450px',
  },
  overviewContentRightBox: {
    display: 'flex',
    padding: '0 40%',
  },
  overviewContentRight: {
    width: '763px',
    padding: '24px 12px',
  },
  overviewContentRightText: {
    margin: '0 10px',
    minWidth: '74px',
    fontSize: '16px',
  },
  linksContainer: {
    backgroundColor: uscmPalette.background.main,
    display: 'flex',
    maxWidth: '1440px',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    borderTop: `1px solid var(--borders-border-4, ${uscmPalette.secondary.main})`,
  },
  linkStyle: {
    color: uscmPalette.selected.main,
    paddingLeft: '30px',
    listStyleType: 'disc',
  },
});

export const ApiOverviewComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const apiName = useMemo(() => {
    const { pathname } = location;
    const pathNameArray = pathname.split('/');
    const pathNameArrayLength = pathNameArray.length;
    return (
      pathNameArray[pathNameArrayLength - 1]
        .charAt(0)
        .toLocaleUpperCase('en-US') +
      pathNameArray[pathNameArrayLength - 1].slice(1)
    );
  }, [location]);

  const data = useGetApiCatalogEntity(apiName);
  const linkList = data?.value?.metadata?.links ?? [];
  return (
    <Box className={classes.outerContainer} data-testid="api-overview">
      <Helmet>
        <title>Catalog | {apiName}</title>
        <meta name="description" content="External Dev Portal" />
      </Helmet>

      <Box className={classes.descriptionContainer}>
        <div className="rbc-box-container-no-padding">
          <Box className={classes.overviewContentHead}>
            <h1>
              <TextStyle kind="heading" size="2xs">
                About
              </TextStyle>
            </h1>
            <Box className={classes.overviewContentBody}>
              <Box className={classes.overviewContentLeft}>
                <h1>
                  <TextStyle kind="ui">Description</TextStyle>
                </h1>
                <h1 className={classes.overviewTextBody}>
                  <TextStyle kind="ui" className={classes.overviewTextStyle}>
                    {data?.value?.spec?.description as string}
                  </TextStyle>
                </h1>
              </Box>
              <Box className={classes.overviewContentRight}>
                <Box className={classes.overviewContentRightBox}>
                  <h1 className={classes.overviewContentRightText}>
                    <TextStyle kind="ui">Version</TextStyle>
                  </h1>
                  <h1 className={classes.overviewContentRightText}>
                    <TextStyle kind="ui">System</TextStyle>
                  </h1>
                </Box>
                <Box className={classes.overviewContentRightBox}>
                  <h1 className={classes.overviewContentRightText}>
                    <TextStyle kind="ui">
                      {(data?.value?.spec?.version as string) ?? 'default'}
                    </TextStyle>
                  </h1>
                  <h1 className={classes.overviewContentRightText}>
                    <TextStyle kind="ui">
                      {(data?.value?.spec?.system as string) ?? 'default'}
                    </TextStyle>
                  </h1>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.linksContainer}>
            <h1 className={classes.overviewTextStyle}>
              <TextStyle kind="heading" size="2xs">
                Links
              </TextStyle>
            </h1>
            {linkList.length !== 0 ? (
              <Box>
                <List className={classes.linkStyle}>
                  {linkList.map(link => (
                    <li key={link.title}>
                      <CTAText
                        elementType="link"
                        url={link.url}
                        srLabel={`For reading more information about ${link.title}`}
                      >
                        {link.title}
                      </CTAText>
                    </li>
                  ))}
                </List>
              </Box>
            ) : (
              <TextStyle className={classes.overviewContentRightText} kind="ui">
                No links defined for this entity.
              </TextStyle>
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );
};
