import { RbcShieldLogo, rbcColorStyles, rbcColors } from '@internal/shared';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router';

const useStyles = (primaryColor: string, secondaryColor: string) =>
  makeStyles(theme => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: theme.spacing(9),
      padding: theme.spacing(0, 2.5),
      backgroundColor: primaryColor,
      borderBottom: `1px solid ${rbcColorStyles.border3}`,
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      maxWidth: theme.spacing(140),
      minHeight: theme.spacing(9),
    },
    button: {
      '& #iconButton span': {
        color: secondaryColor,
        fill: secondaryColor,
      },
    },
  }));

export interface HtnAppBarProps {
  primaryColor?: string;
  secondaryColor?: string;
  whiteLogo?: boolean;
  centerImage?: string;
}

const HtnAppBar = ({
  primaryColor = rbcColors.white,
  secondaryColor = rbcColors.rbcBlue,
  whiteLogo = false,
  centerImage,
}: HtnAppBarProps) => {
  const classes = useStyles(primaryColor, secondaryColor)();
  const navigate = useNavigate();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <RbcShieldLogo
          onClick={() => {
            navigate('/htn');
          }}
          width={34}
          height={44}
          white={whiteLogo}
        />
        {centerImage && <img src={centerImage} alt="logo" />}
      </Box>
    </Box>
  );
};

export default HtnAppBar;
