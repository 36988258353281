import { TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@internal/shared';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      gap: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  title: {
    ...fonts.rbcFuture.heading,
    fontWeight: 'normal',
    fontSize: '28pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '23pt',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20pt',
      textAlign: 'center',
    },
  },
  description: {
    ...fonts.primary,
  },
  video: {
    backgroundImage: "url('../../business-api-thumbnail.jpg')",
    [theme.breakpoints.down('sm')]: {
      height: '360px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      objectFit: 'fill',
    },
  },
}));

const BfsVideoComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <iframe
          width="450"
          height="253"
          src="https://youtu.be/6aB6MrpIRnc"
          title="YouTube video player"
          allowFullScreen
          className={classes.video}
        />
      </div>
      <div className={classes.textContainer}>
        <TextStyle kind="heading" elementType="h2" className={classes.title}>
          What are Business Banking APIs?
        </TextStyle>
        <TextStyle
          kind="paragraph"
          elementType="p"
          className={classes.description}
        >
          Business Banking Application Programming Interfaces (APIs) connect
          your business accounts and systems to help your organization make
          digital payments, send and receive alerts, connect to accounting
          platforms and more—without having to sign into online banking.
        </TextStyle>
      </div>
    </div>
  );
};

export default BfsVideoComponent;
