/* eslint-disable no-console */
import { createApiRef } from '@backstage/core-plugin-api';
import Taplytics from '@taplytics/js-sdk';
import { Config } from '@backstage/config';

export const TaplyticsApiRef = createApiRef<TaplyticsApi>({
  id: 'plugin.taplytics.service',
});

export interface TaplyticsApi {
  isFeatureEnabled(featureName: string): boolean;
  getVariable(
    name: string,
    defaultValue: string | number | boolean | object,
  ): any;
  waitForLoaded(): Promise<void>;
  getFeatureFlags(): Promise<Record<string, string>>;
}

export class TaplyticsImpl implements TaplyticsApi {
  private constructor(options: { apiKey: string }) {
    this.init(options).catch(err => {
      console.error('Failed to initialize Taplytics', err);
    });
  }

  getFeatureFlags(): Promise<Record<string, string>> {
    return Taplytics.getRunningFeatureFlags();
  }

  static fromConfig(config: Config) {
    const apiKey = config.getOptionalString('taplyticsApiKey') || '';
    return new TaplyticsImpl({ apiKey });
  }

  private async init(options: { apiKey: string }) {
    await Taplytics.start(options.apiKey);
  }

  waitForLoaded(): Promise<void> {
    return Taplytics.waitForLoaded();
  }

  /**
   * This check doesnt wait for Taplytics to be loaded
   * @param featureName
   * @returns
   */
  isFeatureEnabled(featureName: string) {
    return Taplytics.featureFlagEnabled(featureName);
  }

  getVariable(name: string, defaultValue: string | number | boolean | object) {
    return (Taplytics.variable(name, defaultValue) as any).value;
  }
}
