import { Box, Grid, makeStyles } from '@material-ui/core';
import { Destination } from '@rig/common';
import {
  BoxContainer,
  CTA,
  MenuGroup,
  RBCActionPanel,
  RBCIconButton,
  RBCNavTabs,
  RBCPanel,
  RBCProfileBox,
} from '@rig/rw-core-components';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useProfile, useSignOut } from '../../../hooks/';
import { uscmColors, uscmPalette } from '../../../themes';

const useStyles = makeStyles({
  pageContainer: {
    margin: 0,
    padding: 0,
  },
  navTabLabel: {
    cursor: 'none',
  },
  navRightLabel: {
    color: uscmPalette.background.main,
    fontSize: '16px',
    fontWeight: 400,
  },

  searchLabel: {
    marginLeft: '12px',
    fontSize: '16px',
    fontWeight: 300,
  },
  rbcSearchButton: {
    paddingRight: '4px',
    color: uscmPalette.background.main,
  },
  closeIcon: {
    marginBottom: '12px',
  },
  searchInputField: {
    height: '52px',
    fontSize: '1rem',
  },
  menuBarContainer: {
    maxWidth: '1440px',
    margin: 'auto',
  },
  navStyle: {
    backgroundColor: uscmColors.uscmBlue,
  },
  navStyleErrorPage: {
    backgroundColor: uscmColors.uscmBlue,
    justifyContent: 'end',
  },
  customNavTabLi: { padding: '14px 0' },
  customNavTab: {
    color: uscmPalette.background.main,
    fontSize: '14px',
    padding: '16px 20px',
    minWidth: '80px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: uscmPalette.primary.alt,
    },
  },
  customNavTabMobile: {
    color: uscmPalette.primary.main,
    outline: 'none',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: '16px 20px',
    position: 'relative',
    '&:focus': {
      border: `1px solid ${uscmPalette.primary.main}`,
    },
  },
  activeTab: {
    backgroundColor: uscmPalette.primary.main,
  },
});

interface LineOfBusinessMenuBarPropsType {
  menuBarLabels?: Array<Destination>;
}

interface TabMapping {
  [key: string]: string;
}

const tabMapping: TabMapping = {
  '/uscm': 'Home',
  '/rbc-api-catalog': 'Catalog',
  '/docs/default/component/uscm-resources/': 'Resources',
};

function LineOfBusinessMenuBar({
  menuBarLabels,
}: LineOfBusinessMenuBarPropsType) {
  const classes = useStyles();
  const location = useLocation();

  const [openedProfilePanel, setOpenedProfilePanel] = useState(false);
  const profile = useProfile();

  const screenWidth = window.innerWidth;
  const signOut = useSignOut();
  const [openPanel, setOpenPanel] = useState(false);
  const openNavigationPanel = () => {
    setOpenPanel(!openPanel);
  };

  const isActiveTab = (activeTabLabel: string) => {
    const { pathname } = location;
    return (
      tabMapping[pathname] === activeTabLabel ||
      pathname
        .toLocaleLowerCase('en-US')
        .includes(activeTabLabel.toLocaleLowerCase('en-US'))
    );
  };

  return (
    <Grid
      className="uscm-menu-bar rbc-nav-product"
      data-testid="rbc-nav-bar"
      role="navigation"
    >
      <nav
        aria-label="help"
        className={menuBarLabels ? classes.navStyle : classes.navStyleErrorPage}
      >
        {menuBarLabels &&
          (screenWidth <= 600 ? (
            <Box
              className="rbc-nav-product-left navigation-menu"
              data-testid="nav-left-side"
            >
              <Box className="hamburger-icon">
                <RBCIconButton
                  icon="hamburger"
                  data-testid="hamburger-icon-btn"
                  iconPosition="left"
                  iconSize="s"
                  onClick={openNavigationPanel}
                  tabIndex={0}
                  aria-label="hamburger icon button"
                >
                  Menu
                </RBCIconButton>
              </Box>

              <RBCPanel
                open={openPanel}
                role="dialog"
                onClose={openNavigationPanel}
                showScrim={openPanel}
                size={300}
                position="left"
                initiator="Home"
                aria-label="open hamburger"
              >
                <Box role="presentation" className="rbc-panel-scrim" />

                <RBCIconButton
                  icon="times"
                  data-testid="hamburger-menu-grp-btn"
                  onClick={openNavigationPanel}
                  className={classes.closeIcon}
                  aria-label="hamburger menu group button"
                  iconSize="s"
                />

                <MenuGroup label="US Cash Management">
                  {menuBarLabels &&
                    menuBarLabels.map((item: Destination) => (
                      <Link
                        className={classes.customNavTabMobile}
                        to={item.src}
                        onClick={openNavigationPanel}
                        role="listitem"
                        key={item.src}
                        tabIndex={0}
                        aria-label={item.label}
                      >
                        {item.label}
                      </Link>
                    ))}
                </MenuGroup>
              </RBCPanel>
            </Box>
          ) : (
            <Box className="rbc-nav-product-left" data-testid="nav-left-side">
              <RBCNavTabs
                role="tablist"
                className="rbc-nav-tabs--opposite"
                density="compact"
                containerWidth="fill"
                distributeEvenly={false}
              >
                {menuBarLabels &&
                  menuBarLabels.map((item: Destination) => (
                    <li
                      role="presentation"
                      className={classes.customNavTabLi}
                      key={item.destinationId}
                    >
                      <Link
                        className={`${
                          isActiveTab(item.label) ? classes.activeTab : ''
                        } ${classes.customNavTab}`}
                        to={item.src}
                        aria-selected={isActiveTab(item.label)}
                        role="tab"
                        id={`tab-${item.destinationId}`}
                        aria-controls={`tabpanel-${item.destinationId}`}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
              </RBCNavTabs>
            </Box>
          ))}
        {/* commenting the profile for phase 1 */}
        {/* <Box className="rbc-nav-product-right" data-testid="nav-right-side">
          <RBCIconButton
            className="rbc-nav-suite-profile-button"
            iconPosition="solo"
            icon="profile"
            iconSize={breakPoint.isAtMost ? 's' : 16}
            id="profile-button"
            onClick={() => openLogoutHandler()}
            aria-label="profile icon"
          />
        </Box> */}
      </nav>
      <RBCActionPanel
        initiator="profile-button"
        headerText="Profile and Notifications"
        open={openedProfilePanel}
        onClose={() => setOpenedProfilePanel(false)}
        className="actionPanelLogout"
      >
        <BoxContainer
          background="alternate"
          outline="none"
          className="rbc-nav-suite-profile-box-container"
        >
          <RBCProfileBox
            itemId="profile-1"
            prefixIcon="profile"
            user={{ fullName: profile.value?.displayName || 'Guest' }}
            description={profile.value?.email || 'guest.example.com'}
          />
        </BoxContainer>
        <BoxContainer
          background="alternate"
          outline="none"
          className="signOutButtonContainer"
        >
          <CTA
            priority="primary"
            size="normal"
            aria-label="Sign Out"
            onClick={() => {
              setOpenedProfilePanel(false);
              signOut();
            }}
          >
            Sign Out
          </CTA>
        </BoxContainer>
      </RBCActionPanel>
    </Grid>
  );
}

export default LineOfBusinessMenuBar;
