import { LOB } from '@internal/shared';
import { Box, makeStyles } from '@material-ui/core';
import { TextStyle } from '@rig/rw-core-components';
import React from 'react';
import bfsImg from '../../../assets/bfs.jpeg';
import uscmImg from '../../../assets/uscm.jpeg';
import obImg from '../../../assets/use-cases.jpeg';
import LineOfBusinessCard, { LineOfBusiness } from './LineOfBusinessCard';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    cardsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    card: {
      alignSelf: 'stretch',
      width: '100%',
      maxWidth: 360,
      minHeight: 240,
      '&:not(:last-child)': {
        marginRight: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'unset',
        maxWidth: 800,
        '&:not(:last-child)': {
          marginRight: theme.spacing(0),
          marginBottom: theme.spacing(3),
        },
      },
    },
  };
});

export const linesOfBusiness: LineOfBusiness[] = [
  {
    name: 'Business Financial Services',
    description:
      'Explore a wide range of business banking solutions, including accounts, credit  cards, loans, payments and advice to help your business succeed.',
    lob: LOB.BFS,
    image: bfsImg,
  },
  {
    name: 'US Cash Management',
    description:
      'RBC Clear™ was developed to put you in full control of your working  capital, with enhanced visibility, efficiency and transparency',
    lob: LOB.USCM,
    image: uscmImg,
  },
  {
    name: 'Open Banking',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: obImg,
  },
];

const LinesOfBusiness = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <TextStyle
        kind="heading"
        size="md"
        elementType="h2"
        className={classes.title}
      >
        Connect With Our Lines Of Business
      </TextStyle>

      <Box className={classes.cardsContainer}>
        {linesOfBusiness?.map((lob, index) => (
          <LineOfBusinessCard key={index} item={lob} className={classes.card} />
        ))}
      </Box>
    </Box>
  );
};

export default LinesOfBusiness;
