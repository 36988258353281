import { rbcColors } from '@internal/shared';
import { Box } from '@material-ui/core';
import React from 'react';

function HtnFooter() {
  return (
    <div>
      <Box
        sx={{
          bgcolor: rbcColors.rbcBlueTint1,
          height: '48px',
          width: '100%',
        }}
      />
      <Box
        sx={{
          bgcolor: rbcColors.grey,
          width: '100%',
          height: '48px',
        }}
      />
    </div>
  );
}

export default HtnFooter;
