import { Box, makeStyles } from '@material-ui/core';
import { CTAText, Divider, TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { rbcColors } from '../../../themes';

const useStyles = (primaryColor: string) =>
  makeStyles(theme => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: theme.spacing(8),
      padding: theme.spacing(3, 2.5),
      backgroundColor: primaryColor,
      color: rbcColors.white,
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(3),
      flexWrap: 'wrap',
      width: '100%',
      maxWidth: theme.spacing(140),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    linksContainer: {
      display: 'flex',
      gap: theme.spacing(1.5),
      '& > a': {
        color: rbcColors.white,
        '&:visited': {
          color: rbcColors.white,
        },
        '&::after': {
          border: `1px solid ${rbcColors.white}`,
        },
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    divider: {
      '&:last-child': {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }));

export const rbcFooterLinks = [
  { label: 'Legal', url: 'https://www.rbc.com/legal' },
  { label: 'Terms and Conditions', url: '' },
  {
    label: 'Accessibility',
    url: 'https://www.rbc.com/accessibility/index.html',
  },
  {
    label: 'Privacy',
    url: 'https://www.rbc.com/privacysecurity/ca/online-privacy.html',
  },
];

const year = new Date().getFullYear();

export interface RbcHomeFooterProps {
  primaryColor?: string;
}

const RbcHomeFooter = ({
  primaryColor = rbcColors.carbonDarkTint1,
}: RbcHomeFooterProps) => {
  const classes = useStyles(primaryColor)();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <TextStyle kind="ui">
          RBC - Copyright {year}, All rights reserved.
        </TextStyle>
        <Box className={classes.linksContainer}>
          {rbcFooterLinks.map((link, index) => (
            <React.Fragment key={index}>
              <CTAText
                elementType="link"
                url={link.url}
                destinationType="external"
                density="compact"
                target="_blank"
              >
                {link.label}
              </CTAText>
              <Box className={classes.divider}>
                <Divider direction="vertical" />
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default RbcHomeFooter;
