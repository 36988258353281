import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const h2HOverviewPlugin = createPlugin({
  id: 'h2h-overview',
  routes: {
    root: rootRouteRef,
  },
});

export const H2HOverviewPage = h2HOverviewPlugin.provide(
  createRoutableExtension({
    name: 'H2HOverviewPage',
    component: () => import('./pages/H2HOverviewPage').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
