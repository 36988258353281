import { rbcColors } from '@internal/shared';
import { Box, styled } from '@material-ui/core';
import { CTA, Divider, TextParagraph } from '@rig/rw-core-components';
import React from 'react';

const StyledCard = styled(Box)(() => ({
  width: '360px',
  border: `1px solid ${rbcColors.greyTint3}`,
  borderTop: '8px solid #3589bb',
  '& button': {
    textDecoration: 'none',
    fontWeight: 300,
  },
  '& .card-content': {
    margin: '24px',
  },
  '& .card-footer': {
    margin: '16px 24px',
  },
  '& .rbc-card-body-title': {
    marginBottom: 0,
  },
  '& .card-heading': {
    marginBottom: '16px',
  },
}));

function HtnTile({ onClick }: { onClick: () => void }) {
  return (
    <StyledCard>
      <Box className="card-content">
        <Box className="card-heading">
          <legend className="rbc-card-body-title">
            Pay With Pretend Points API
          </legend>
          <TextParagraph>v1.0</TextParagraph>
        </Box>
        <TextParagraph>
          Manage and earn points in a loyalty and rewards system.
        </TextParagraph>
      </Box>
      <Divider />
      <Box className="card-footer">
        <CTA priority="tertiary" aria-label="Learn more" onClick={onClick}>
          Learn more
        </CTA>
      </Box>
    </StyledCard>
  );
}

export default HtnTile;
