import type { Destination } from '@rig/common';
import React, { useEffect, useMemo, useRef } from 'react';
import { useIsUscmPhaseOneEnabled } from '../../hooks';
import { LOB } from '../../models';
import { useSharedStore } from '../../store';
import { bfsColors } from '../../themes';
import LineOfBusinessMenuBar from './LineOfBusiness/LineOfBusinessMenuBar';
import RbcHomeMenuBar, { RbcHomeMenuBarProps } from './RbcHome/RbcHomeMenuBar';
import { lobLabels } from './labels';

export const getLabels = (
  hideLabels: boolean,
  isUscmPhaseOneEnabled: boolean,
  lob?: LOB,
): Destination[] | undefined => {
  if (hideLabels || !lob) {
    return undefined;
  }

  const showHome = lob !== LOB.USCM || !isUscmPhaseOneEnabled;

  return showHome
    ? lobLabels[lob]
    : lobLabels[lob].filter(item => item.destinationId !== 'home');
};

const lobProps: { [key in LOB]?: RbcHomeMenuBarProps } = {
  [LOB.BFS]: {
    primaryColor: bfsColors.bfsBlue,
    secondaryColor: bfsColors.bfsBlueDark,
  },
};

export interface MenuBarProps {
  hideLabels: boolean;
}

const MenuBar = ({ hideLabels }: MenuBarProps) => {
  const isUscmPhaseOneEnabled = useIsUscmPhaseOneEnabled();
  const { currentLob } = useSharedStore();
  const menuBarLabels = useMemo(
    () => getLabels(hideLabels, isUscmPhaseOneEnabled, currentLob),
    [hideLabels, isUscmPhaseOneEnabled, currentLob],
  );
  const menuBarProps = lobProps[currentLob];
  const renderNum = useRef(0);

  useEffect(() => {
    // We use this and apply it to the rendered elements as the `key` property to
    // get around a bug in RIG's RbcNavTabs component. Without this fix, we get an error every time
    // the length of the menuBarLabels array changes (e.g. between different LOBs).
    renderNum.current++;
  });

  return (
    <>
      {currentLob === LOB.USCM ? (
        <LineOfBusinessMenuBar
          menuBarLabels={menuBarLabels}
          key={renderNum.current + 1}
        />
      ) : (
        <RbcHomeMenuBar
          menuBarLabels={menuBarLabels}
          key={renderNum.current}
          {...menuBarProps}
        />
      )}
    </>
  );
};

export default MenuBar;
