import { Content } from '@backstage/core-components';
import {
  FeatureToggles,
  useTaplytics,
} from '@internal/backstage-plugin-taplytics-react';
import {
  Footer,
  LOB,
  MenuBar,
  rbcColors,
  uscmPalette,
  useLineOfBusiness,
  useSharedStore,
} from '@internal/shared';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import React, {
  PropsWithChildren,
  ReactNode,
  Suspense,
  memo,
  useEffect,
  useRef,
} from 'react';
import { useLocation } from 'react-router';
import { AppBar } from '../home';
import { useFeatureRedirect } from '../../hooks';

const getBackgroundColor = (
  isNotUscmPage: boolean,
  isUscmRbcCatalog: boolean,
) => {
  if (isNotUscmPage) {
    return rbcColors.white;
  }
  if (isUscmRbcCatalog) {
    return uscmPalette.primary.light;
  }
  return uscmPalette.background.alt;
};

const useRootStyles = (isNotUscmPage: boolean, isUscmRbcCatalog: boolean) =>
  makeStyles({
    pageContainer: {
      margin: 'auto',
      padding: 0,
      backgroundColor: getBackgroundColor(isNotUscmPage, isUscmRbcCatalog),
    },
    contentContainer: {
      '&#rootContent > article': {
        ...(isNotUscmPage && { padding: 0 }),
      },
    },
    wrapper: {
      minHeight: 'calc(100vh - 168px)',
    },
    skipLink: {
      position: 'absolute',
      top: '-40px',
      left: '0',
      background: '#000',
      color: '#fff',
      padding: '8px',
      zIndex: 100,
      '&:focus': {
        top: '0',
      },
    },
  });

const MainContent = memo(
  ({
    hideLabels,
    isNotUscmPage,
    children,
    hideNavAndFooter,
  }: {
    hideLabels: boolean;
    isNotUscmPage: boolean;
    children: ReactNode;
    hideNavAndFooter?: boolean;
  }) => {
    const location = useLocation();
    const classes = useRootStyles(
      isNotUscmPage,
      location.pathname === '/rbc-api-catalog',
    )();

    const mainContentRef = useRef(null);

    const SkipToMainContentButton = () => (
      <a
        href="#rootContent"
        className={classes.skipLink}
        aria-label="Skip to the main content"
        onClick={() => {
          if (mainContentRef.current) {
            (mainContentRef.current as HTMLElement).focus();
          }
        }}
      >
        Skip to Main Content
      </a>
    );

    useEffect(() => {
      if (mainContentRef.current) {
        (mainContentRef.current as HTMLElement).setAttribute('tabindex', '-1');
      }
    }, []);

    return (
      <Box
        sx={{
          height: '100%',
        }}
        className={classes.pageContainer}
      >
        {!hideNavAndFooter && (
          <>
            <SkipToMainContentButton />
            <AppBar />
            <MenuBar hideLabels={hideLabels} />
          </>
        )}

        <Box
          id="rootContent"
          className={classes.contentContainer}
          ref={mainContentRef}
        >
          <Content className={classes.wrapper}>{children}</Content>
        </Box>
        {!hideNavAndFooter && <Footer />}
      </Box>
    );
  },
);

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { currentLob, setCurrentLob } = useSharedStore();
  const lobInUrl = useLineOfBusiness();
  const location = useLocation();
  const { isFlagEnabled, loaded } = useTaplytics();
  useFeatureRedirect();

  useEffect(() => {
    // Whenever we are on a different home page, update the current LOB in the store and set local storage
    if (lobInUrl && lobInUrl !== currentLob) {
      setCurrentLob(lobInUrl as LOB);
    }
  }, [setCurrentLob, lobInUrl, currentLob]);

  return (
    <Suspense>
      {loaded && (
        <MainContent
          hideLabels={location.pathname.includes('not-allow')}
          isNotUscmPage={!location.pathname.includes('uscm')}
          hideNavAndFooter={
            isFlagEnabled(FeatureToggles.HACK_THE_NORTH) &&
            location.pathname.includes('htn')
          }
        >
          {children}
        </MainContent>
      )}
    </Suspense>
  );
};
