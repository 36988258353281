import { uscmPalette } from '@internal/shared';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ApiAndHostToHost, NewsAndUpdates, QuickAccess } from './index';

const useStyles = makeStyles({
  pageContainer: {
    margin: 0,
    padding: 0,
    overflowY: 'scroll',
    backgroundColor: uscmPalette.background.main,
  },
  page: {
    overflowY: 'scroll',
  },
});

const HomePage = () => {
  const classes = useStyles();
  return (
    <>
      {/* <Banner /> */}
      <Box
        className={classes.pageContainer}
        role="main"
        data-testid="home-page-container"
      >
        <Helmet>
          <title>External Dev Portal</title>
          <meta name="description" content="External Dev Portal" />
        </Helmet>
        <QuickAccess />
        <ApiAndHostToHost />
        <NewsAndUpdates />
      </Box>
    </>
  );
};

export default HomePage;
