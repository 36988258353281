import React from 'react';
import { LOB } from '../../models';
import { useSharedStore } from '../../store';
import { rbcColors } from '../../themes';
import LineOfBusinessFooter from './LineOfBusiness/LineOfBusinessFooter';
import RbcHomeFooter, { RbcHomeFooterProps } from './RbcHome/RbcHomeFooter';

const lobProps: { [key in LOB]?: RbcHomeFooterProps } = {
  [LOB.BFS]: {
    primaryColor: rbcColors.darkGrey,
  },
};

const Footer = () => {
  const { currentLob } = useSharedStore();
  const footerProps = lobProps[currentLob];

  return (
    <>
      {currentLob === LOB.USCM ? (
        <LineOfBusinessFooter />
      ) : (
        <RbcHomeFooter {...footerProps} />
      )}
    </>
  );
};

export default Footer;
