import { Box, makeStyles } from '@material-ui/core';
import { TextStyle } from '@rig/rw-core-components';
import React from 'react';
import LearnCard, { LearnDetails } from './LearnCard';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    subTitle: {
      marginBottom: theme.spacing(2),
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '24px',
    },
    cardsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    card: {
      alignSelf: 'stretch',
      width: '100%',
      maxWidth: 360,
      '&:not(:last-child)': {
        marginRight: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'unset',
        maxWidth: 800,
        '&:not(:last-child)': {
          marginRight: theme.spacing(0),
          marginBottom: theme.spacing(3),
        },
      },
    },
  };
});

export const learnCardDetails: LearnDetails[] = [
  {
    title: 'Blogs',
    description: 'Check out the cool technology that is being built in RBC',
    link: 'dummylink',
  },
  {
    title: 'FAQs',
    description:
      'Find answers to frequently asked questions on the developer portal',
    link: 'dummylink',
  },
  {
    title: 'Documentation',
    description: 'Read documentation on how RBC APIs work and how to connect',
    link: 'dummylink',
  },
];

const LearnSection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <TextStyle
        kind="heading"
        size="md"
        elementType="h2"
        className={classes.title}
      >
        Learn
      </TextStyle>
      <TextStyle
        kind="heading"
        size="sm"
        elementType="h3"
        emphasis="low"
        className={classes.subTitle}
      >
        Educate yourself on how to connect your applications with RBC
      </TextStyle>
      <Box className={classes.cardsContainer}>
        {learnCardDetails?.map((learnDetail, index) => (
          <LearnCard key={index} item={learnDetail} className={classes.card} />
        ))}
      </Box>
    </Box>
  );
};

export default LearnSection;
