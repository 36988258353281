import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import stayUpToDateImage from '../../../assets/stay-up-to-date.svg';

const useStyles = makeStyles(theme => {
  return {
    sectionImageRoot: {
      backgroundImage: `url(${stayUpToDateImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      position: 'absolute',
      width: '100%',
      maxWidth: 447,
      height: 436,
      right: 0,
      top: 0,
      [theme.breakpoints.down('md')]: {
        position: 'relative',
        maxHeight: 436,
        backgroundPosition: 'center',
        maxWidth: 'unset',
        height: 300,
      },
    },
  };
});

const StayUpToDateSecitionImage = () => {
  const classes = useStyles();
  return <Box className={classes.sectionImageRoot} />;
};

export default StayUpToDateSecitionImage;
