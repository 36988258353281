import { Box } from "@material-ui/core";
import { LayoutContainer } from "@rig/rw-core-components";
import { Outlet } from "react-router";
import HtnAppBar from "./components/HtnAppBar";
import HtnFooter from "./components/HtnFooter";
import HtnNav from "./components/HtnNav";
import React from "react";

const Layout = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box>
      <HtnAppBar />
      <HtnNav />
    </Box>

    <Box
      component="main"
      sx={{
        marginTop: '48px',
        marginBottom: '48px',
        minHeight: 'calc(100vh - 313px)',
      }}
    >
      <LayoutContainer hasMargins>
        <Outlet />
      </LayoutContainer>
    </Box>
    <HtnFooter />
  </Box>
);

export default Layout;