import { Grid, Card as MuiCard, makeStyles } from '@material-ui/core';
import { HeadingLevel, HeadingUseCase, ParagraphUseCase } from '@rig/common';
import { CTA, TextHeading, TextParagraph } from '@rig/rw-core-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { uscmPalette } from '../../themes';

type CardProps = {
  header: string;
  body: string;
  onBtnClick?: () => void;
  buttonLabel?: string;
  buttonPriority: 'primary' | 'secondary' | 'tertiary' | 'accent';
  readOnlyBtn: boolean;
  btnPath?: string;
};

const useStyles = makeStyles({
  cardContainer: {
    width: '100%',
    minWidth: '150px',
    maxWidth: '448px',
    padding: '1.5rem',
    borderRadius: 0,
    background: uscmPalette.background.main,
    boxShadow:
      '0px 3.59106px 7.84712px 0px rgba(37, 37, 37, 0.08), 0px 12.06161px 26.35684px 0px rgba(37, 37, 37, 0.06), 0px 54px 118px 0px rgba(37, 37, 37, 0.04)',
  },
  cardHeader: {
    fontWeight: 400,
  },
  cardBody: {
    marginTop: '5px',
    paddingBottom: '24px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
  },
  btnContainer: {
    justifyContent: 'flex-end',
    width: 'auto',
    margin: 0,
    marginTop: 'auto',
  },
  cardHeadingLabel: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontFamily: 'RBC MS Display',
    lineHeight: '32px',
  },
  readMoreLink: {
    color: uscmPalette.primary.main,
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.5,
    cursor: 'pointer',
    '&:hover': {
      color: uscmPalette.primary.alt,
      textDecoration: 'underline',
    },
  },
});

const Card = (props: CardProps) => {
  const classes = useStyles();
  const {
    header,
    body,
    onBtnClick,
    buttonLabel,
    buttonPriority,
    readOnlyBtn,
    btnPath,
  } = props;

  return (
    <MuiCard className={classes.cardContainer}>
      <TextHeading
        useCase={HeadingUseCase.noticeHeading}
        headingLevel={HeadingLevel.h1}
        className={classes.cardHeadingLabel}
      >
        {header}
      </TextHeading>

      <TextParagraph
        useCase={ParagraphUseCase.default}
        className={classes.cardBody}
      >
        {body}
      </TextParagraph>
      {buttonLabel && (
        <Grid container className={classes.btnContainer}>
          {readOnlyBtn ? (
            <Link
              to={`${btnPath ?? '/docs/default/component/documentation-hub/'}`}
              className={classes.readMoreLink}
              aria-label={buttonLabel}
            >
              {buttonLabel}
            </Link>
          ) : (
            <CTA
              onClick={onBtnClick}
              priority={buttonPriority ?? 'primary'}
              size="small"
            >
              {buttonLabel}
            </CTA>
          )}
        </Grid>
      )}
    </MuiCard>
  );
};

export default Card;
