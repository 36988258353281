import { Box, Typography } from '@material-ui/core';
import { RBCIcon, RBCIconButton } from '@rig/rw-core-components';
import React, { useState } from 'react';
import { uscmPalette } from '../../themes';

export type AlertProps = {
  severity: 'success' | 'error' | 'warning' | 'info' | 'help';
  title: string;
  body: string;
  compact?: boolean;
  open: boolean;
  timeout?: number;
  disableTimeout?: boolean;
};

function severityToProps(severity: string) {
  switch (severity) {
    case 'success':
      return { color: uscmPalette.success.main, icon: 'check-circle-s' };
    case 'error':
      return { color: uscmPalette.error.main, icon: 'error-s' };
    case 'warning':
      return { color: uscmPalette.warning.main, icon: 'warning-tt' };
    case 'info':
      return { color: uscmPalette.info.main, icon: 'info-s' };
    default:
      return { color: uscmPalette.info.main, icon: 'help-s' };
  }
}

const Alert = ({
  severity,
  title,
  body,
  compact = false,
  open,
  timeout = 6000,
  disableTimeout = false,
}: AlertProps) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!disableTimeout) {
    setTimeout(() => {
      handleClose();
    }, timeout);
  }

  return (
    <>
      {isOpen && (
        <Box
          sx={{
            borderTop: `${
              severityToProps(severity).color
            } 4px solid !important`,
            border: `${uscmPalette.secondary.light} 2px solid`,
            padding: '1rem',
            color: severityToProps(severity).color,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: compact ? 'center' : 'inherit',
              flexWrap: compact ? 'wrap' : 'nowrap',
            }}
          >
            <RBCIcon
              name={severityToProps(severity).icon}
              size="sm"
              srLabel="alert box"
              useSprites
              decorative
            />
            <Box
              sx={{
                alignItems: 'center',
                display: compact ? 'flex' : 'inline',
                paddingLeft: compact ? 'inherit' : '0.5rem',
                color: uscmPalette.text.main,
              }}
            >
              <Typography
                variant="body1"
                style={{
                  padding: compact ? '0 0.5rem' : '0 0 0.3rem 0',
                  marginTop: compact ? 'inherit' : '-0.2rem',
                }}
              >
                {title}
                {compact ? ':' : ''}
              </Typography>
              <Typography variant="body2">{body}</Typography>
            </Box>
          </Box>
          <Box sx={{ margin: '0 0.3rem 0 0.5rem', paddingTop: '0.25rem' }}>
            <RBCIconButton
              icon="times"
              iconSize="sm"
              data-testid="close-alert"
              onClick={() => handleClose()}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Alert;
