/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CTAText } from '@rig/rw-core-components';
import React, { ReactNode, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { rbcColors } from '@internal/shared';

const useStyles = makeStyles((theme: Theme) => ({
  accordionTitle: {
    backgroundColor: rbcColors.rbcBlueTint4,
    padding: theme.spacing(2),
    margin: theme.spacing(-2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 0 8px 8px',
  },
  isActive: {
    marginLeft: theme.spacing(1),
  },
  title: {
    fontSize: '12pt',
    [theme.breakpoints.down('xs')]: {
      fontSize: '11pt',
    },
  },
  content: {
    marginTop: theme.spacing(6),
  },
}));

interface AccordionProps {
  title: string;
  children: ReactNode;
}

const Accordion = (props: AccordionProps) => {
  const { title, children } = props;
  const classes = useStyles();

  const [isActive, setIsActive] = useState(false);

  return (
    <React.Fragment>
      <div
        className={classes.accordionTitle}
        tabIndex={0}
        onClick={() => setIsActive(!isActive)}
        role="button"
      >
        <CTAText icon="star-s" density="compact" onClick={() => {}}>
          <div
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className={classes.isActive}>{isActive ? '⌃' : '⌄'}</div>
        </CTAText>
      </div>
      {isActive && <div className={classes.content}>{children}</div>}
    </React.Fragment>
  );
};

export default Accordion;
