import { fonts, rbcColorStyles } from '@internal/shared';
import { Box } from '@material-ui/core';
import makeStyles from '@mui/styles/makeStyles';
import { TextStyle } from '@rig/rw-core-components';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      gap: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: theme.spacing(4),
      },
    },
    sectionInfo: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    form: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    textarea: {
      padding: '11px 15px',
      fontSize: '1rem',
      lineHeight: theme.spacing(3),
      fontFamily: fonts.primary.main,
      minWidth: 280,
      minHeight: 156,
      fontWeight: 300,
      color: rbcColorStyles.textDefault,
      border: `1px solid ${rbcColorStyles.border1}`,
      resize: 'vertical',
      '&:focus': {
        outline: `solid 2px ${rbcColorStyles.interactive1}`,
        outlineOffset: '-2px',
      },
      '&:hover': {
        border: `1px solid ${rbcColorStyles.linkHover}`,
      },
    },
  };
});

export const MAX_CHARACTERS = 250;

const CommentsWidget = ({
  onCommentsChange,
}: {
  onCommentsChange: (comments: string) => void;
}) => {
  const classes = useStyles();
  const [commentsValue, setCommentsValue] = useState('');

  const handleCommentsChange = (value: string) => {
    setCommentsValue(value);
    onCommentsChange(value);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.sectionInfo}>
        <TextStyle kind="ui" elementType="h2">
          Comments (optional)
        </TextStyle>
        <TextStyle kind="ui" emphasis="low" elementType="p">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </TextStyle>
      </Box>
      <Box className={classes.form}>
        <textarea
          maxLength={MAX_CHARACTERS}
          className={classes.textarea}
          id="commentsInput"
          value={commentsValue}
          onChange={e => handleCommentsChange(e.target.value)}
          aria-label="Input comments (optional)"
          placeholder="Input text here"
        />
        <TextStyle kind="ui" size="sm" emphasis="low">
          {commentsValue.length}/{MAX_CHARACTERS} character limit
        </TextStyle>
      </Box>
    </Box>
  );
};

export default CommentsWidget;
