import { RbcShieldLogo, fonts, rbcColors } from '@internal/shared';
import { Box, makeStyles } from '@material-ui/core';
import { CTAText, TextStyle } from '@rig/rw-core-components';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    color: rbcColors.white,
    backgroundColor: rbcColors.rbcBlueTint1,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  section: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  sectionHeading: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  link: {
    '& > a': {
      ...fonts.rbcFuture.heading,
      color: rbcColors.white,
      fontSize: '14px',
      fontWeight: 500,
      '&::after': {
        border: `1px solid ${rbcColors.white}`,
      },
    },
  },
}));

const linkSections = [
  {
    heading: 'About RBC',
    links: [
      { label: 'Our Company', url: '' },
      { label: 'Contact Us', url: '' },
    ],
  },
  {
    heading: 'Resources',
    links: [
      { label: 'API Catalog', url: 'rbc-api-catalog' },
      { label: 'Use cases', url: '' },
      { label: 'Blogs', url: '' },
    ],
  },
  {
    heading: 'Support',
    links: [
      { label: 'FAQs', url: '' },
      { label: 'Contact Us', url: '' },
    ],
  },
];

const Sitemap = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.section}>
        <RbcShieldLogo width={76} height={100} />
      </Box>
      {linkSections.map((section, index) => (
        <Box key={index} className={classes.section}>
          <TextStyle
            kind="heading"
            elementType="h3"
            className={classes.sectionHeading}
          >
            {section.heading}
          </TextStyle>

          {section.links.map((link, i) => (
            <Box key={i} className={classes.link}>
              <CTAText elementType="link" url={link.url} icon="angle-right">
                {link.label}
              </CTAText>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default Sitemap;
