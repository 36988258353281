import { Box, makeStyles } from '@material-ui/core';
import { Destination } from '@rig/common';
import { ProductNavigation, RBCIconButton } from '@rig/rw-core-components';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { rbcColors } from '../../../themes';

const useStyles = (primaryColor: string, secondaryColor: string) =>
  makeStyles({
    menuBar: {
      '&#menuBar': {
        '& ::before': {
          borderColor: primaryColor,
        },
        '& *, *[class*="rbc"]': {
          backgroundColor: primaryColor,
          background: primaryColor,
          borderColor: primaryColor,
        },
        '& li, button': {
          '&:hover, &:hover *, & *:hover, & *:hover *, &:focus, &:focus *, & *:focus, & *:focus *':
            {
              backgroundColor: secondaryColor,
              background: secondaryColor,
              borderColor: secondaryColor,
            },
        },
        '& *[class*="selected"]': {
          '& *': {
            backgroundColor: secondaryColor,
            background: secondaryColor,
            borderColor: secondaryColor,
          },
          '&::before': {
            borderColor: rbcColors.rbcYellow,
          },
        },
      },
    },
  });

export interface RbcHomeMenuBarProps {
  menuBarLabels?: Destination[];
  primaryColor?: string;
  secondaryColor?: string;
}

const RbcHomeMenuBar = ({
  menuBarLabels,
  primaryColor = rbcColors.rbcBlueTint1,
  secondaryColor = rbcColors.rbcBlue,
}: RbcHomeMenuBarProps) => {
  const classes = useStyles(primaryColor, secondaryColor)();
  const location = useLocation();
  const selectedDestination = menuBarLabels?.find(
    item => item.src === location.pathname,
  )?.destinationId;

  return (
    <>
      <Box className={classes.menuBar} id="menuBar">
        {menuBarLabels && (
          <ProductNavigation
            destinations={menuBarLabels}
            selectedDestination={selectedDestination}
            utility1={
              <RBCIconButton
                icon="search"
                iconSize="s"
                className="rbc-icon-button-position-left"
              >
                Search
              </RBCIconButton>
            }
            utility2={
              <RBCIconButton
                icon="phone"
                iconSize="s"
                className="rbc-icon-button-position-left"
              >
                Contact Us
              </RBCIconButton>
            }
          />
        )}
      </Box>
    </>
  );
};

export default RbcHomeMenuBar;
