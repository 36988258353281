import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';
import { rbcShieldLogo, rbcShieldLogoWhite } from '../../assets';

const StyledButton = styled(Button)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

interface RbcShieldLogoProps {
  width: number;
  height: number;
  white?: boolean;
  onClick?: () => void;
}

const RbcShieldLogo = ({
  width,
  height,
  white,
  onClick,
}: RbcShieldLogoProps) => {
  return (
    <StyledButton
      disableElevation
      disableRipple
      disableTouchRipple
      disableFocusRipple
      onClick={onClick}
    >
      {white ? (
        <img
          src={rbcShieldLogoWhite}
          alt="RBC shield logo"
          style={{ height, width }}
          data-testid="logo-white"
        />
      ) : (
        <img
          src={rbcShieldLogo}
          alt="RBC shield logo"
          style={{ height, width }}
          data-testid="logo"
        />
      )}
    </StyledButton>
  );
};

export default RbcShieldLogo;
