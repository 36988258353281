import makeStyles from '@mui/styles/makeStyles';
import { TextStyle } from '@rig/rw-core-components';
import React from 'react';
import StayUpToDateNavButton from './StayUpToDateNavButton';
import StayUpToDateSectionImage from './StayUpToDateSectionImage';

const useStyles = makeStyles(theme => {
  return {
    controlContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    titleContainer: {
      display: 'block',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '100%',
      maxWidth: 448,
      [theme.breakpoints.down('md')]: {
        display: 'block',
        marginBottom: theme.spacing(4),
        maxWidth: 'unset',
      },
    },
    sectionTitle: {
      fontFamily: 'RBC MS Display',
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '36px',
      letterSpacing: '-0.26px',
      marginBottom: theme.spacing(1),
    },
    subTitle: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '24px',
      marginBottom: theme.spacing(4),
    },
    navContainer: {
      minWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    imageSection: {
      display: 'block',
      minWidth: '429px',
      minHeight: '388px',
      marginRight: '96px',
    },
    textSection: {
      display: 'flex',
      width: '100%',
    },
  };
});

interface Props {}

const StayUpToDateSection = ({}: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.controlContainer}>
        <div className={classes.textSection}>
          <div className={classes.titleContainer}>
            <TextStyle
              kind="heading"
              size="md"
              elementType="h2"
              className={classes.sectionTitle}
            >
              Stay Up to Date
            </TextStyle>
            <TextStyle
              kind="heading"
              size="md"
              elementType="h3"
              className={classes.subTitle}
            >
              Get the latest updates from RBC systems
            </TextStyle>
            <div className={classes.navContainer}>
              <StayUpToDateNavButton
                text="News"
                iconName="paperclip"
                hyperlink="news"
              />
              <StayUpToDateNavButton
                text="Status Page"
                iconName="map-marker"
                hyperlink="status"
              />
              <StayUpToDateNavButton
                text="Change Logs"
                iconName="list-group"
                hyperlink="changelogs"
              />
            </div>
          </div>
        </div>
        <StayUpToDateSectionImage />
      </div>
    </>
  );
};

export default StayUpToDateSection;
