import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { LOB, useSharedStore } from '@internal/shared';
import useAsyncRetry from 'react-use/lib/useAsyncRetry';

export const lobEntityFields = [
  'kind, metadata.description, metadata.name, metadata.title, metadata.tags, spec.type, spec.version, spec.tag',
];

export interface LobFilterProps {
  tags?: LOB[];
  specTypes?: string[];
}

export const useGetLobApiCatalog = (props?: LobFilterProps) => {
  const { tags, specTypes } = props ?? {};
  const selectedLob = useSharedStore(store => store.filterByLob);
  const catalogApi = useApi(catalogApiRef);
  const tagsFilter = tags ?? [selectedLob ?? 'uscm'];

  const specTypeFilter = specTypes?.length ? specTypes : ['h2h', 'openapi'];

  const data = useAsyncRetry(async () => {
    const response = await catalogApi.getEntities({
      filter: [
        {
          'spec.type': specTypeFilter,
          'metadata.tags': tagsFilter[0] !== 'rbc' ? tagsFilter : [],
        },
      ],
      fields: lobEntityFields,
    });
    return response.items;
  }, [selectedLob]);

  return data;
};
