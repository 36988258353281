import { DiscoveryApi } from '@backstage/core-plugin-api';
import { H2hTestApi } from './h2hTestApi';
import {} from '@internal/plugin-h2h-data-module-common';
import { ScanDTO1 } from '@internal/backstage-plugin-chorus-av-data-module-common';

export class H2hTestApiClient implements H2hTestApi {
  discoveryApi: DiscoveryApi;

  constructor({ discoveryApi }: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = discoveryApi;
  }

  private async fetch<T = any>(input: string, init: RequestInit): Promise<T> {
    const proxyUri = await this.discoveryApi.getBaseUrl('h2h');
    const resp = await fetch(`${proxyUri}${input}`, init);
    const body = await resp.json();
    if (!resp.ok) throw new Error(`${body.error.message}`);
    return body;
  }

  async validate(file: FormData): Promise<any> {
    const proxyUri = await this.discoveryApi.getBaseUrl('h2h');
    const resp = await fetch(`${proxyUri}/validate`, {
      method: 'POST',
      body: file,
    });
    const text = resp.text();
    if (!resp.ok) {
      throw new Error(`Error fetching data: ${text}`);
    }
    return text;
  }

  async scan(file: FormData): Promise<ScanDTO1> {
    return await this.fetch<ScanDTO1>(`/scan`, {
      method: 'POST',
      body: file,
    });
  }
}
