/* eslint-disable no-console */
import { useEffect } from 'react';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import { analyticsApiRef, useApi } from '@backstage/core-plugin-api';

export const NavTechdocAddonComponent = () => {
  const analyticsApi = useApi(analyticsApiRef);
  const mainNav = useShadowRootElements<HTMLImageElement>(['.md-nav__link']);
  const downloadLinks = useShadowRootElements<HTMLImageElement>([
    '.downloadLink',
  ]);
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const target = e.target as HTMLElement;
        target.click();
        target.setAttribute(
          'aria-hidden',
          target.getAttribute('aria-hidden') === 'true' ? 'false' : 'true',
        );
        target.setAttribute(
          'aria-expanded',
          target.getAttribute('aria-expanded') === 'true' ? 'false' : 'true',
        );
        target.setAttribute('role', 'button');
        target.setAttribute('aria-label', 'Toggle navigation item');
      }
    };

    // TODO: fix the download, it is not directly downloading the file.
    // const handleDownload = (event: MouseEvent) => {
    //   event.preventDefault();
    //   const target = event.target as HTMLElement;
    //   const fileUrl = target.getAttribute('href');
    //   const fileName = target.getAttribute('download');
    //   if (fileUrl && fileName) {
    //     // GA4
    //     const eventAction = `h2h_${
    //       fileUrl.includes('nacha') ? 'nacha' : 'pain002'
    //     }_doc_download`;
    //     analyticsApi.captureEvent({
    //       action: eventAction,
    //       context: { extension: 'n/a', pluginId: 'h2h', routeRef: 'h2h-nacha' },
    //       subject: eventAction,
    //     });

    //     const anchor = document.createElement('a');
    //     anchor.href = fileUrl;
    //     anchor.download = fileUrl.split('/').pop() || 'download'; // Suggest a filename
    //     document.body.appendChild(anchor);
    //     anchor.click();
    //     document.body.removeChild(anchor);

    //     fetch(fileUrl)
    //       .then(response => {
    //         console.log(response)
    //         return response.blob();
    //       })
    //       .then(blob => {
    //         console.log('blob', blob)
    //         const tempLink = document.createElement('a');
    //         const url = window.URL.createObjectURL(blob);
    //         console.log('url', url);
    //         tempLink.href = url;
    //         tempLink.download = fileName;
    //         document.body.appendChild(tempLink);
    //         tempLink.click();
    //         document.body.removeChild(tempLink);
    //         window.URL.revokeObjectURL(url);
    //       })
    //       .catch(error => {
    //         console.error('Download Failed:', error.message);
    //       });
    //   } else {
    //     console.error('Download Failed');
    //   }
    // };

    const updateAriaCurrent = () => {
      mainNav.forEach(navItem => {
        const name = navItem.textContent || navItem.innerText;
        if (navItem.classList.contains('md-nav__link--active')) {
          navItem.setAttribute('aria-current', 'page');
          navItem.setAttribute('role', 'link');
          navItem.setAttribute('aria-label', `${name} Selected`);
        } else {
          navItem.removeAttribute('aria-selected');
          navItem.removeAttribute('aria-label');
        }
      });
    };

    const observer = new MutationObserver(updateAriaCurrent);
    mainNav.forEach(navItem => {
      observer.observe(navItem, {
        attributes: true,
        attributeFilter: ['class'],
      });
    });

    mainNav.forEach(navItem => {
      navItem.addEventListener('keypress', handleKeyPress);
    });

    // downloadLinks.forEach((link: any) => {
    //   link.addEventListener('click', handleDownload);
    // });

    updateAriaCurrent();

    return () => {
      mainNav.forEach(navItem => {
        navItem.removeEventListener('keypress', handleKeyPress);
      });

      // downloadLinks.forEach((link: any) => {
      //   link.removeEventListener('click', handleDownload);
      // });
    };
  }, [mainNav, downloadLinks, analyticsApi]);

  return null;
};
