import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = (image: string) =>
  makeStyles(theme => {
    return {
      root: {
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: 200,
        flex: 'none',
        [theme.breakpoints.down('md')]: {
          backgroundSize: 'cover',
        },
      },
    };
  });

interface Props {
  image: string;
}

const FeaturedApiImage = ({ image }: Props) => {
  const classes = useStyles(image)();

  return <Box className={classes.root} />;
};

export default FeaturedApiImage;
