import { LOB, rbcColors } from '@internal/shared';
import { makeStyles } from '@material-ui/core';
import { TextParagraph, TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { useNavigate } from 'react-router';

const useStyles = (image: string) =>
  makeStyles(theme => {
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        background: `linear-gradient(0deg, ${rbcColors.darkBlueTint1}80 1.27%, ${rbcColors.darkBlueTint1} 100%), url(${image}) lightgray 50% / cover no-repeat`,
        position: 'relative',
        padding: theme.spacing(3),
        textAlign: 'start',
        color: rbcColors.white,
        cursor: 'pointer',
        '&:active, &:hover': {
          background: `linear-gradient(0deg, ${rbcColors.darkBlue}80 1.27%, ${rbcColors.darkBlue} 100%), url(${image}) lightgray 50% / cover no-repeat`,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          display: 'block',
          top: '-4px',
          left: '-4px',
          height: 'calc(100% + 8px)',
          width: 'calc(100% + 8px)',
          border: '1px solid #006ac3',
          borderRadius: 'inherit',
          opacity: 0,
          pointerEvents: 'none',
          transform: 'scale(0.97)',
          transition:
            'opacity 0.15s cubic-bezier(0.7, 0, 0.3, 1), transform 0.15s cubic-bezier(0.7, 0, 0.3, 1)',
        },
        '&:focus': {
          outline: '-webkit-focus-ring-color auto 1px;',
          '&::after': {
            opacity: 1,
            transform: `scale(1.02)`,
          },
        },
        [theme.breakpoints.down('md')]: {
          backgroundSize: 'cover',
        },
      },
      title: {
        marginBottom: theme.spacing(2),
      },
    };
  });

export type LineOfBusiness = {
  name: string;
  description: string;
  lob?: LOB;
  image: string;
};

type Props = {
  item: LineOfBusiness;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const LineOfBusinessCard = ({ item, className = '' }: Props) => {
  const classes = useStyles(item.image)();
  const navigate = useNavigate();

  return (
    <button
      className={`${classes.root} ${className}`}
      tabIndex={0}
      onClick={() => item.lob && navigate(`/${item.lob}`)}
      aria-label={item.name}
    >
      <TextStyle
        kind="heading"
        size="md"
        elementType="h3"
        className={classes.title}
      >
        {item.name}
      </TextStyle>
      <TextParagraph>{item.description}</TextParagraph>
    </button>
  );
};

export default LineOfBusinessCard;
