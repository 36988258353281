import {
  SupportedThemes,
  UnifiedThemeOptions,
  createUnifiedTheme,
  themes,
} from '@backstage/theme';
import { rbcColors, uscmPalette } from '@internal/shared';
import { baseThemeOptions } from './devPortalV2';

const currTheme = themes.light.getTheme('v5') as SupportedThemes;

const docsThemeOptions: UnifiedThemeOptions = {
  ...baseThemeOptions,
  components: {
    MuiGrid: {
      styleOverrides: {
        item: {
          padding: 0,
        },
      },
    },
    BackstageContent: {
      styleOverrides: {
        root: {
          background: uscmPalette.background.main,
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '2px !important',
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundImage: 'unset',
          boxShadow: 'unset',
          paddingBottom: currTheme.spacing(1),
        },
        title: {
          color: rbcColors.black,
          fontFamily: 'RBC MS Display',
          fontSize: '28px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '36px',
          letterSpacing: '-0.26px',
        },
        subtitle: {
          color: currTheme.palette.primary.dark,
        },
        type: {
          display: 'none',
          color: currTheme.palette.primary.dark,
        },
        rightItemsBox: {
          display: 'none',
        },
      },
    },
    BackstagePage: {
      styleOverrides: {
        root: {
          maxWidth: '1440px',
          margin: 'auto',
          height: '100%',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    PluginCatalogEntityContextMenu: {
      styleOverrides: {
        button: {
          color: uscmPalette.primary.dark,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          display: 'none !important',
        },
      },
    },
  },
};

export const docsHubTheme = createUnifiedTheme(docsThemeOptions);
