/**
 * This hook is only temporary to allow for the fact that the entire app's basepath is `/uscm`.
 * At the moment, this means that different mock lines of business must be at, .e.g, `/uscm/bfs`.
 * Once we have reconfigured things to allow different basepaths, this should be deprecated in
 * favour of `useLineOfBusiness`.
 *
 * Example: for URL `localhost:3000/uscm/bfs`, returns `bfs`.
 *
 * @see useLineOfBusiness
 */

export const useSubLineOfBusiness = () => {
  return window.location.pathname.split('/')[2];
};
