import {
  LOB,
  LOBFullName,
  fonts,
  rbcColorStyles,
  rbcColors,
} from '@internal/shared';
import { Box, makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { CTAText, TextStyle } from '@rig/rw-core-components';
import React from 'react';
import FeaturedApiImage from './FeaturedApiImage';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      alignSelf: 'stretch',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 352,
      border: `1px solid ${rbcColorStyles.border3}`,
      backgroundColor: rbcColorStyles.background1,
      '&:not(:last-child)': {
        marginRight: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'unset',
        width: '100%',
        maxWidth: 800,
        '&:not(:last-child)': {
          marginRight: theme.spacing(0),
          marginBottom: theme.spacing(3),
        },
      },
    },
    image: {
      maxWidth: 352,
      height: 200,
    },
    mainContent: {
      padding: theme.spacing(3),
      height: '100%',
      minHeight: 300,
      [theme.breakpoints.down('md')]: {
        minHeight: 'unset',
      },
    },
    title: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(2),
      ...fonts.rbcLegacy.heading,
    },
    chip: {
      borderRadius: theme.spacing(2),
      backgroundColor: rbcColorStyles.actionSuccess,
      color: rbcColors.white,
      height: theme.spacing(3),
      width: 'fit-content',
      margin: theme.spacing(0, 0.5, 0.5, 0),
    },
    linkBar: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2, 3),
      borderTop: `1px solid ${rbcColorStyles.border4}`,
    },
  };
});

export type FeaturedApi = {
  kind: string;
  metadata: {
    title: string;
    name: string;
    description: string;
    tags?: string[];
  };
  image: string;
};

export interface FeaturedApiCardProps {
  api: FeaturedApi;
}

const FeaturedApiCard = ({ api }: FeaturedApiCardProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <FeaturedApiImage image={api.image} />
      <Box className={classes.mainContent}>
        {api.metadata.tags?.map((tag, index) => (
          <Chip
            key={index}
            label={LOBFullName[tag as LOB]}
            className={classes.chip}
            data-testid="lob-chip"
          />
        ))}
        <TextStyle
          kind="heading"
          size="md"
          elementType="h3"
          className={classes.title}
        >
          {api.metadata.title}
        </TextStyle>
        <TextStyle kind="ui" emphasis="low">
          {api.metadata.description}
        </TextStyle>
      </Box>
      <Box className={classes.linkBar}>
        <CTAText
          url={`catalog/default/${api.kind}/${api.metadata.name}`}
          elementType="link"
        >
          API Reference
        </CTAText>
      </Box>
    </Box>
  );
};

export default FeaturedApiCard;
