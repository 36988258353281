import { ThemeProvider, createTheme } from '@mui/material/styles';
import { render } from '@testing-library/react';
import type TestingLibrary from '@testing-library/react/types';
import React, { ReactNode } from 'react';

type RenderWithProvidersType = (
  children: ReactNode,
) => TestingLibrary.RenderResult<
  typeof TestingLibrary.queries,
  HTMLElement,
  HTMLElement
>;

const theme = createTheme();

export const renderWithProviders: RenderWithProvidersType = (
  children: ReactNode,
) => render(<ThemeProvider theme={theme}>{children}</ThemeProvider>);
