import { TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fonts, rbcColors } from '@internal/shared';

// GPT-4 helped write CSS, Aug 1st 2024
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  mainTitle: {
    ...fonts.rbcFuture.heading,
    marginBottom: theme.spacing(2),
    fontSize: '30pt',
    color: rbcColors.rbcBlueTint1,
    fontWeight: 'normal',
    [theme.breakpoints.down('xs')]: {
      fontSize: '22pt',
    },
  },
  subTitle: {
    ...fonts.rbcFuture.heading,
  },
  roundedImage: {
    borderRadius: '16px',
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      borderRadius: '5px',
      width: '111%',
    },
  },
  imgContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const BfsLandingPageTitleComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <TextStyle
          kind="heading"
          elementType="h1"
          className={classes.mainTitle}
        >
          Streamline Payments and More with RBC Business Banking APIs
        </TextStyle>
        <TextStyle
          kind="paragraph"
          elementType="p"
          className={classes.subTitle}
        >
          Enjoy real-time embedded treasury capabilities to help digitize your
          business.
        </TextStyle>
      </div>
      <div className={classes.imgContainer}>
        <img
          alt="Man typing on a laptop while sitting in a wheelchair outside"
          src={require('../images/api-bnr.jpg')}
          width="425"
          height="300"
          className={classes.roundedImage}
        />
      </div>
    </div>
  );
};

export default BfsLandingPageTitleComponent;
