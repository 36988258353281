import { Box } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import BfsLandingPageTitleComponent from './landing-page/bfs-title/BfsLandingPageTitleComponent';
import BfsVideoComponent from './landing-page/bfs-video/BfsVideoComponent';
import { LayoutBlock } from '@internal/shared';
import ExploreApis from './landing-page/explore-apis/explore-apis';
import BfsKeyBenefits from './landing-page/bfs-key-benefits/bfs-key-benefits';
import CodeSample from './landing-page/code-sample/code-sample';

const BfsHomePage = () => {
  return (
    <Box role="main">
      <Helmet>
        <title>BFS External Developer Portal</title>
        <meta name="description" content="BFS External Developer Portal" />
      </Helmet>
      <Box>
        <LayoutBlock
          alternateBackground
          maxWidth={840}
          paddingYDesktop={30}
          paddingYMobile={0}
        >
          <BfsLandingPageTitleComponent />
        </LayoutBlock>
        <LayoutBlock maxWidth={960} paddingYDesktop={50}>
          <BfsVideoComponent />
        </LayoutBlock>
        <LayoutBlock maxWidth={960} paddingYDesktop={50} paddingYMobile={25}>
          <BfsKeyBenefits />
        </LayoutBlock>
        <LayoutBlock maxWidth={960} paddingYDesktop={50} paddingYMobile={50}>
          <ExploreApis />
        </LayoutBlock>
        <LayoutBlock maxWidth={960} paddingYDesktop={50} paddingYMobile={50}>
          <CodeSample />
        </LayoutBlock>
      </Box>
    </Box>
  );
};

export default BfsHomePage;
