import { identityApiRef, useApi } from '@backstage/core-plugin-api';

export const useSignOut = () => {
  const identityApi = useApi(identityApiRef);

  return async () => {
    await identityApi.signOut();
    await fetch(`${window.location.origin}/pkmslogout`);
    window.location.reload();
  };
};
