import { rbcColors } from '@internal/shared';

import makeStyles from '@mui/styles/makeStyles';
import { RBCIcon, TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => {
  return {
    navButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: rbcColors.white,
      padding: '16px 20px',
      paddingLeft: '20px',
      color: rbcColors.rbcBlueTint1,
    },
    navButtonInfo: {
      display: 'flex',
      gap: '16px',
    },
    navButtonText: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '20px',
    },
  };
});

interface Props {
  text: string;
  iconName: string;
  hyperlink: string;
}

const StayUpToDateNavButton = ({ text, iconName, hyperlink }: Props) => {
  const classes = useStyles();
  // const navigate = useNavigate();

  return (
    <>
      <Link to={hyperlink}>
        <div className={classes.navButton}>
          <div className={classes.navButtonInfo}>
            <RBCIcon name={iconName} size="sm" />
            <TextStyle
              kind="heading"
              size="md"
              elementType="h4"
              className={classes.navButtonText}
            >
              {text}
            </TextStyle>
          </div>
          <RBCIcon name="chevron-right" size="sm" />
        </div>
      </Link>
    </>
  );
};

export default StayUpToDateNavButton;
