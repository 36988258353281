import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useMemo } from 'react';

export const useCurrentEnv = () => {
  const api = useApi(configApiRef);
  return useMemo(() => {
    const env =
      api.getOptionalString('environment')?.toLocaleUpperCase('en-US') ?? '';
    return { env: env, isLocal: env === 'LOCAL', isProd: env === 'PROD' };
  }, [api]);
};
