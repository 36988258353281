import React from 'react';

const RbcClearLogoIcon = () => {
  return (
    <svg
      width="146"
      height="32"
      viewBox="0 0 146 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="RBC Clear Logo">
        <path
          id="Vector"
          d="M9.52539 7.72949C11.2868 7.72949 12.86 8.10962 14.023 8.94893C15.1333 9.81458 15.8936 11.1168 15.8936 12.961C15.8936 15.2907 14.3504 17.1877 12.3181 17.9479L18.0652 26.4049H13.7294L8.6334 18.6555H6.16818V26.4049H2.6416V11.4706C2.6416 10.2512 2.6416 9.27637 2.15232 8.6516C1.88133 8.29781 1.36571 8.00048 0.959229 7.97413V7.72949H9.52163H9.52539ZM8.71243 16.6193C11.2605 16.6193 12.2616 14.5041 12.2616 13.0438C12.2616 11.2824 11.0422 9.7393 8.84793 9.7393H6.16441V16.6231H8.71243V16.6193Z"
          fill="#FFCA32"
        />
        <path
          id="Vector_2"
          d="M28.438 7.72949C32.4501 7.72949 34.2642 9.92372 34.2642 12.1744C34.2642 14.2068 32.992 15.4789 31.3661 16.1301C33.9141 16.943 35.1863 18.4071 35.1863 20.846C35.1863 22.2272 34.5916 23.6386 33.4249 24.6962C32.3146 25.7275 30.4967 26.4049 28.5998 26.4049H21.1477V11.4706C21.1477 10.2512 21.1477 9.27637 20.6584 8.6516C20.3874 8.29781 19.8718 8.00048 19.4653 7.97413V7.72949H28.4342H28.438ZM27.4895 15.4789C29.1982 15.4789 30.6322 14.2858 30.6322 12.4454C30.6322 10.9023 29.33 9.70919 27.2976 9.70919H24.6668V15.4827H27.4858L27.4895 15.4789ZM27.4895 24.4215C30.0375 24.4215 31.4452 22.7692 31.4452 20.6013C31.4452 18.7308 29.9547 17.4586 27.975 17.4586H24.6668V24.4215H27.4858H27.4895Z"
          fill="#FFCA32"
        />
        <path
          id="Vector_3"
          d="M52.691 11.3613C51.8254 10.1155 50.1693 9.11063 48.3552 9.11063C45.9691 9.11063 42.7436 10.9548 42.7436 17.052C42.7436 23.1492 46.7557 25.0198 49.3827 25.0198C52.0098 25.0198 53.583 23.2019 53.583 23.2019V25.6671C53.583 25.6671 51.9571 26.8602 48.3515 26.8602C43.6619 26.8602 38.8933 24.1503 38.8933 17.0483C38.8933 10.5973 43.1764 7.20996 48.3515 7.20996C50.9785 7.20996 53.1765 8.13207 53.9067 8.5122L52.6872 11.3575L52.691 11.3613Z"
          fill="#FFCA32"
        />
        <path
          id="Vector_4"
          d="M79.1423 11.2553C78.4121 10.2541 76.4587 8.84273 74.6145 8.84273C71.1181 8.84273 68.4609 12.0682 68.4609 16.946C68.4609 22.0683 71.5509 25.2374 74.9382 25.2374C76.5905 25.2374 78.461 24.6427 79.9778 23.3668V25.5874C78.7057 26.374 76.8088 26.8896 74.6936 26.8896C69.733 26.8896 65.8301 23.3141 65.8301 17.1341C65.8301 11.4961 69.2174 7.24316 74.7199 7.24316C76.6432 7.24316 78.5965 7.94697 80.166 8.92177L79.1347 11.2515L79.1423 11.2553Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M87.4037 26.405H85.1267V5.61816H87.4037V26.405Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M94.8294 19.3589C94.8294 22.9908 96.9446 25.2377 99.79 25.2377C101.036 25.2377 102.338 24.7786 103.557 23.9656L104.182 25.1587C102.447 26.4609 100.795 26.8373 99.0598 26.8373C95.1832 26.8373 92.4207 23.6683 92.4207 19.5207C92.4207 15.3731 95.2397 12.1777 99.0071 12.1777C101.988 12.1777 104.645 14.1838 104.645 18.0867C104.645 18.3577 104.619 18.7905 104.592 19.0615L94.8369 19.3589H94.8294ZM102.172 17.0555C102.172 14.8048 100.708 13.5854 99.0824 13.5854C96.8317 13.5854 95.1004 15.2376 94.8821 17.9211L102.146 17.6501C102.172 17.4318 102.172 17.2437 102.172 17.0555Z"
          fill="white"
        />
        <path
          id="Vector_7"
          d="M120.761 26.4047H120.381C119.922 26.1337 119.542 25.9982 119.079 25.9982C117.969 25.9982 116.937 26.894 114.988 26.894C112.846 26.894 111.194 26.0283 110.136 24.6997C109.079 23.3711 108.563 21.5834 108.563 19.6865C108.563 16.8148 109.783 14.8614 111.491 13.563C112.658 12.6409 114.066 12.2344 115.503 12.2344C116.776 12.2344 118.051 12.5581 118.782 12.829L120.761 12.3699V26.4084V26.4047ZM115.748 13.721C113.309 13.721 111.032 15.5652 111.032 19.4945C111.032 21.7452 111.683 23.4765 112.955 24.4814C113.607 25.0234 114.446 25.3207 115.394 25.3207C116.749 25.3207 117.969 24.5906 118.458 24.1841V14.4813C117.807 14.1012 116.806 13.721 115.748 13.721Z"
          fill="white"
        />
        <path
          id="Vector_8"
          d="M133.851 14.4245C132.956 14.0444 132.199 13.8562 131.465 13.8562C130.569 13.8562 129.73 14.1272 128.755 14.6692V26.4044H126.478V12.5276H126.832C127.348 12.8249 127.807 12.9341 128.24 12.9341C129.324 12.9341 130.569 12.1738 132.387 12.1738C133.038 12.1738 133.769 12.283 134.638 12.5276L133.851 14.4245Z"
          fill="white"
        />
        <path
          id="Vector_9"
          d="M137.961 9.20812V12.268H137.442V9.20812H136.358V8.82422H139.045V9.20812H137.961ZM142.839 12.268L142.512 9.27586L141.642 12.268H141.236L140.378 9.27586L140.028 12.268H139.519L139.971 8.82422H140.637L141.439 11.6696L142.252 8.82422H142.941L143.37 12.268H142.839Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1227_36995">
          <rect
            width="145"
            height="30.7908"
            fill="white"
            transform="translate(0.5 0.604492)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RbcClearLogoIcon;
