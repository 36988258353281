import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import typingImg from '../../../assets/bothHandsTyping.svg';

const useStyles = makeStyles(theme => {
  return {
    root: {
      backgroundImage: `url(${typingImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      position: 'absolute',
      width: '100%',
      maxWidth: 365,
      height: 403,
      right: 0,
      top: 0,
      zIndex: 0,
      [theme.breakpoints.down('sm')]: {
        height: 300,
        maxWidth: 250,
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'unset',
        position: 'relative',
        marginBottom: theme.spacing(-8),
        backgroundPosition: 'center',
      },
    },
  };
});

const SignUpSectionImage = () => {
  const classes = useStyles();
  return <Box className={classes.root} />;
};

export default SignUpSectionImage;
