import React from 'react';
import BfsIcon from './BfsIcon';
import RbcCapitalMarketsIcon from './RbcCapitalMarketsIcon';
import RbcClearLogoIcon from './RbcClearLogoIcon';
import { useSubLineOfBusiness } from '@internal/shared';

export enum DynamicIconPosition {
  left = 'left',
  center = 'center',
}

function DynamicIcon(props: any) {
  const lob: string = useSubLineOfBusiness();

  if (lob === 'bfs') {
    return <BfsIcon />;
  } else if (
    props.iconPosition === DynamicIconPosition.center &&
    lob !== 'bfs'
  ) {
    return <RbcClearLogoIcon />;
  }
  return <RbcCapitalMarketsIcon />;
}

export default DynamicIcon;
