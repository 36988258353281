import { illustrativeLines } from '../assets';
import { rbcColors, uscmColors, uscmPalette } from '../themes';

export function mapLOBToThemeForAppBar(lob: string) {
  const baseStyles = {
    appName: {
      color: `var(--text-foregrounds-text-default, ${rbcColors.blackTint1})`,
      fontWeight: 400,
      fontSize: '16px',
    },
    topNavItems: {
      padding: '1rem',
      '&:focus': {
        border: '1px solid #FFF',
      },
    },
    topNav: {
      maxWidth: '1440px',
      margin: 'auto !important',
      padding: '1rem 1rem 1rem 0',
      display: 'flex',
      height: '3.5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      borderBottom: `1px solid ${uscmPalette.border.main}`,
      backgroundImage: `url(${illustrativeLines.default})`,
    },
    appBarContainer: {
      backgroundColor: uscmColors.uscmBlue,
    },
  };

  switch (lob) {
    case 'bfs':
      return {
        ...baseStyles,
        topNav: {
          ...baseStyles.topNav,
          backgroundImage: `url("https://media.istockphoto.com/id/90945139/photo/horizontal-money-background.jpg?s=612x612&w=0&k=20&c=m8AQnHIdQpVFogPC0jKg0UKUnnMgCtUgwXakw1fO-1o=")`,
        },
        appBarContainer: {
          backgroundColor: 'blue',
        },
      };
    default:
      return baseStyles;
  }
}
