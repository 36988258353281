import { useEffect } from 'react';
import { useAllowUsers } from './useAllowUsers';
import { useLocation, useNavigate } from 'react-router';
import {
  FeatureToggles,
  useTaplytics,
} from '@internal/backstage-plugin-taplytics-react';

export const useFeatureRedirect = () => {
  const { isAllowed, checkFinished } = useAllowUsers();
  const navigate = useNavigate();
  const { isFlagEnabled, loaded } = useTaplytics();
  const location = useLocation();

  useEffect(() => {
    if (!loaded) return;
    // Check which page we are on
    const isUscmPage = location.pathname.includes('uscm');
    const isHackTheNorthPage = location.pathname.includes('htn');
    const isDragonFlyPage = !isUscmPage && !isHackTheNorthPage;
    const isUscmNotAllowPage = location.pathname.includes('/uscm/not-allow');

    // Check if the feature toggles are off
    const isHackTheNorthOff =
      isHackTheNorthPage && !isFlagEnabled(FeatureToggles.HACK_THE_NORTH);

    const isDragonFlyFeaturesOff =
      isDragonFlyPage && !isFlagEnabled(FeatureToggles.DRAGONFLY_FEATURES);

    const isUscmPhaseOneDisabled =
      isUscmPage && !isFlagEnabled(FeatureToggles.USCM_PHASE_ONE);

    const isUscmMarketoDisabledOrNotAllowed =
      isUscmPage &&
      isFlagEnabled(FeatureToggles.USCM_MARKETO) &&
      checkFinished &&
      !isAllowed;

    const isUscmPagesNotAllowed =
      isUscmPhaseOneDisabled || isUscmMarketoDisabledOrNotAllowed;

    // Redirect to the not-allow page if the user is not allowed into USCM
    if (isUscmPagesNotAllowed) {
      navigate('/uscm/not-allow');
    }

    // Redirect to the uscm page if DRAGONFLY_FEATURES or HTN toggle is off or USCM features are enabled
    if (
      isDragonFlyFeaturesOff ||
      isHackTheNorthOff ||
      (isUscmNotAllowPage && !isUscmPagesNotAllowed)
    ) {
      navigate('/uscm/rbc-api-catalog');
    }
  }, [
    isAllowed,
    location.pathname,
    navigate,
    loaded,
    isFlagEnabled,
    checkFinished,
  ]);
};
