import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import useCasesImg from '../../../assets/use-cases.jpeg';

const useStyles = makeStyles(theme => {
  return {
    root: {
      backgroundImage: `url(${useCasesImg})`,
      backgroundRepeat: 'no-repeat',
      width: '100%',
      maxWidth: 360,
      minHeight: 240,
      flex: 'none',
      [theme.breakpoints.down('md')]: {
        backgroundSize: 'cover',
      },
    },
  };
});

const UseCasesSectionImage = () => {
  const classes = useStyles();
  return <Box className={classes.root} />;
};

export default UseCasesSectionImage;
