import { Card, uscmPalette } from '@internal/shared';
import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { HeadingLevel, HeadingUseCase } from '@rig/common';
import { TextHeading } from '@rig/rw-core-components';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    marginBottom: '2rem',
    fontSize: '28px',
    fontFamily: 'RBC MS Display',
    fontWeight: 400,
    lineHeight: '36px',
  },
  cardContainer: {
    paddingBottom: '8px',
    display: 'flex',
    gap: '24px',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  newsContainer: {
    backgroundColor: uscmPalette.primary.light,
    padding: '24px',
  },
  outerCardContainer: {
    backgroundColor: uscmPalette.background.main,
    padding: '24px',
    maxWidth: '1440px',
    margin: 'auto',
  },
  innerContainer: {
    margin: '0 24px',
  },
}));

const mockData = [
  {
    header: 'New API Available',
    body: 'The Power of the RBC USCM. Delivered as host-to-host for your business success.',
    path: '/docs/default/component/new-and-updates',
    buttonLabel: 'Read',
  },
  {
    header: 'Announcing H2H Release',
    body: 'The Power of the RBC USCM. Delivered as host-to-host for your business success.',
    path: '/docs/default/component/new-and-updates',
  },
  {
    header: 'Quick Smart Guide',
    body: 'The Power of the RBC USCM. Delivered as host-to-host for your business success.',
    path: '/docs/default/component/new-and-updates',
  },
  {
    header: 'Use Cases',
    body: 'The Power of the RBC USCM. Delivered as host-to-host for your business success.',
    path: '/docs/default/component/new-and-updates',
  },
];

const NewsAndUpdates = () => {
  const classes = useStyles();

  return (
    <Box className={classes.newsContainer}>
      <Box className={classes.outerCardContainer}>
        <Box className={classes.innerContainer}>
          <TextHeading
            headingLevel={HeadingLevel.h1}
            className={classes.title}
            useCase={HeadingUseCase.noticeHeading}
          >
            Latest News & Updates
          </TextHeading>

          <Grid container className={classes.cardContainer}>
            {mockData.map((data, id) => (
              <Card
                key={id}
                body={data.body}
                header={data.header}
                buttonLabel="Read more"
                buttonPriority="secondary"
                readOnlyBtn
                btnPath={data.path}
              />
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsAndUpdates;
