import { LayoutBlock, Sitemap, rbcColors } from '@internal/shared';
import React from 'react';
import LearnSection from '../rbcHome/Learn/LearnSection';
import StayUpToDateSection from '../rbcHome/StayUpToDate/StayUpToDateSection';
import Build from '../rbcHome/Build/Build';

const ResourcesPage = () => {
  return (
    <>
      <LayoutBlock>
        <LearnSection />
      </LayoutBlock>

      <LayoutBlock alternateBackground>
        <StayUpToDateSection />
      </LayoutBlock>

      <LayoutBlock>
        <Build />
      </LayoutBlock>

      <LayoutBlock
        paddingYDesktop={64}
        backgroundColor={rbcColors.rbcBlueTint1}
      >
        <Sitemap />
      </LayoutBlock>
    </>
  );
};

export default ResourcesPage;
