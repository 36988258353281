import { Box, makeStyles } from '@material-ui/core';
import { CTAText, DataTable } from '@rig/rw-core-components';
import React from 'react';

const useStyles = makeStyles({
  labelText: {
    '& span': {
      fontWeight: '400 !important',
    },
  },
});

const H2hFileUploadTable = ({
  btnLabel,
  fileName,
  fileCreatedDate,
  isLoading,
  handleClick,
}: {
  btnLabel: string;
  fileName: string;
  fileCreatedDate?: Date;
  isLoading: boolean;
  handleClick: () => void;
}) => {
  const classes = useStyles();
  const formattedDate = fileCreatedDate
    ? fileCreatedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : '-';
  const fileLabel = fileName ? fileName : '-';
  const fileUploadTableDetails = [
    {
      buttonLabel: 'Upload',
      fileType: 'Payment Initiation - Nacha',
      fileLabel: fileLabel,
      date: formattedDate,
    },
  ];
  return (
    <DataTable
      srLabel="H2h Test Plugin File Upload"
      caption="H2h Test Plugin File Upload"
      definition={[
        {
          alignment: 'left',
          class: `column-1 ${classes.labelText}`,
          key: 'fileType',
          label: 'Type',
          truncate: 'wrap',
          visible: true,
        },
        {
          class: `column-2 ${classes.labelText}`,
          key: 'fileLabel',
          label: 'Filename',
          truncate: 'wrap',
        },
        {
          alignment: 'left',
          class: `column-3 ${classes.labelText}`,
          key: 'date',
          label: 'Created Date',
          truncate: 'wrap',
        },
        {
          alignment: 'left',
          class: 'column-4',
          key: 'buttonLabel',
          label: 'Action',
        },
      ]}
      tableData={fileUploadTableDetails}
      templates={{
        buttonLabel: function noRefCheck() {
          return (
            <Box className="h2hUploadBtn">
              <CTAText
                density="compact"
                elementType="button"
                icon=""
                data-testid="upload-file-column"
                type="button"
                disabled={isLoading}
                onClick={handleClick}
                srLabel="Upload file for validation"
              >
                {btnLabel}
              </CTAText>
            </Box>
          );
        },
      }}
      zebraStriped
    />
  );
};

export default H2hFileUploadTable;
