import { Entity } from '@backstage/catalog-model';
import { LOB, useGetApiCatalog } from '@internal/shared';
import { Box, makeStyles } from '@material-ui/core';
import { CTAText, TextStyle } from '@rig/rw-core-components';
import React, { useMemo } from 'react';
import amortizationImg from '../../../assets/amortization.png';
import downPaymentImg from '../../../assets/down-payment.png';
import sdbInventoryImg from '../../../assets/sdb-inventory.png';
import FeaturedApiCard, { FeaturedApi } from './FeaturedApiCard';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    headingContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginBottom: theme.spacing(3),
      },
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    cardsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  };
});

const mockImages = [amortizationImg, downPaymentImg, sdbInventoryImg];

export const getFeaturedApis = (apis: Entity[] | undefined): FeaturedApi[] => {
  if (!apis) {
    return [];
  }
  const filteredApis = apis?.slice(0, 3);

  return filteredApis.map((api, index) => ({
    kind: api.kind,
    image: mockImages[index],
    metadata: {
      name: api.metadata.name,
      title: api.metadata.title ?? '',
      description: api.metadata.description ?? '',
      tags: api.metadata.tags,
    },
  }));
};

const FeaturedApis = () => {
  const classes = useStyles();
  const { value: allApis } = useGetApiCatalog({
    tags: [LOB.RB],
    specTypes: ['openapi'],
  });
  const featuredApis = useMemo(() => getFeaturedApis(allApis), [allApis]);

  return (
    <Box className={classes.root}>
      <Box className={classes.headingContainer}>
        <TextStyle
          kind="heading"
          size="md"
          elementType="h2"
          className={classes.title}
        >
          Explore Our APIs
        </TextStyle>
        <CTAText
          destinationType="indicator"
          url="rbc-api-catalog"
          elementType="link"
        >
          View All APIs
        </CTAText>
      </Box>

      <Box className={classes.cardsContainer}>
        {featuredApis?.map((api, index) => (
          <FeaturedApiCard key={index} api={api} />
        ))}
      </Box>
    </Box>
  );
};

export default FeaturedApis;
