import { TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@internal/shared';

const useStyles = makeStyles(theme => ({
  listContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  },
  // GPT-4o helped write this class, Aug 15th, 2024
  listItem: {
    marginBottom: theme.spacing(1),
    fontWeight: 'lighter',
    display: 'list-item',
    fontSize: '12pt',
    paddingLeft: theme.spacing(5),
    position: 'relative',
    // GPT-4o helped write this &::before, Aug 15th, 2024
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: '12px',
      height: '12px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${require('../images/ui-checkmark-blue.svg')})`,
      transform: 'translateX(-160%)',
      position: 'absolute',
      top: '9%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  title: {
    ...fonts.rbcFuture.heading,
    fontSize: '26pt',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      fontSize: '20pt',
    },
  },
}));

const BfsKeyBenefits = () => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <TextStyle elementType="h2" kind="heading" className={classes.title}>
          Key Benefits of Business Banking APIs
        </TextStyle>
      </div>
      <ul className={classes.listContainer}>
        <div>
          <li className={classes.listItem}>
            Real-time capabilities to help optimize working capital and
            visibility of cash position
          </li>
          <li className={classes.listItem}>
            Direct data integration into existing systems, including treasury
            management, enterprise resource planning or accounting solutions
          </li>
        </div>
        <div>
          <li className={classes.listItem}>
            Improved straight-through payment processing rates help to minimize
            troubleshooting and delayed transactions
          </li>
          <li className={classes.listItem}>
            Less manual reconciliation for payables and receivables teams.
            Receive real-time acknowledgement back to your financial system.
          </li>
        </div>
      </ul>
    </div>
  );
};

export default BfsKeyBenefits;
