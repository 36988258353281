import { Box, makeStyles } from '@material-ui/core';
import { BannerNotice } from '@rig/rw-core-components';
import React from 'react';

const useStyles = makeStyles(() => {
  return {
    banner: {
      marginTop: '24px',
      width: '100%',
      maxWidth: '1440px',
      margin: 'auto',
    },
  };
});
function NotAllowErrorPage() {
  const classes = useStyles();

  return (
    <Box className={classes.banner}>
      <BannerNotice kind="danger" heading="The site can’t be reached">
        It appears that you don’t have the permission to access this page or
        resource
      </BannerNotice>
    </Box>
  );
}

export default NotAllowErrorPage;
