/* istanbul ignore file */
import { MediaProvider } from '@rig/rw-core-components';
import React, { ReactNode } from 'react';
import 'style-loader!css-loader!sass-loader!./styles.scss';
import { useMediaContext } from '../../hooks/useMediaContext';

interface MediaProviderProps {
  children: ReactNode;
}

export const RigTheme: React.FunctionComponent<MediaProviderProps> = props => {
  const ctx = useMediaContext();

  return (
    <MediaProvider mediaRegistryConfig={ctx}>{props.children}</MediaProvider>
  );
};
