import { ApiExplorerPage, apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  TechDocsIndexPage,
  TechDocsReaderPage,
  techdocsPlugin,
} from '@backstage/plugin-techdocs';
import React from 'react';
import { apis } from './apis';
import { Root } from './components/Root';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  ProxiedSignInPage,
} from '@backstage/core-components';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { SearchPage } from '@backstage/plugin-search';
import { UnifiedThemeProvider } from '@backstage/theme';
import { H2HOverviewPage } from '@internal/backstage-plugin-h2h-overview';
import { H2HTestPage } from '@internal/plugin-h2h-test';
import { LobCatalogPage } from '@internal/plugin-lob-catalog';
import { RbcApiCatalogPage } from '@internal/plugin-rbc-api-catalog';
import { RigTheme } from '@internal/shared';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import {
  LinkComponentProps,
  LinkComponentProvider,
  Loader,
} from '@rig/rw-core-components';
import { Navigate, Route } from 'react-router';
import { Link } from 'react-router-dom';
import NotAllowErrorPage from './components/NotAllowErrorPage';
import BfsHomePage from './components/bfs/BfsHomePage';
import { entityPage } from './components/catalog/EntityPage';
import ContactUsPage from './components/contactUs/ContactUsPage';
import ContactUsPageConfirmation from './components/contactUs/confirmation/ContactUsPageConfirmation';
import RbcHomePage from './components/rbcHome/RbcHomePage';
import ResourcesPage from './components/resources/ResourcesPage';
import { searchPage } from './components/search/SearchPage';
import { devportalThemeV2 } from './theme/devPortalV2';
import {
  useTaplytics,
  FeatureToggles,
} from '@internal/backstage-plugin-taplytics-react';
import { Router } from '@internal/backstage-plugin-htn-react';
import { DocRenderPage } from '@internal/backstage-plugin-doc-render';

const generateClassName = createGenerateClassName({
  seed: 'edp',
  productionPrefix: 'edp',
});

const app = createApp({
  components: {
    SignInPage: props => <ProxiedSignInPage {...props} provider="guest" />,
  },
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'devportal-theme-v2',
      title: 'Dev Portal Theme v2',
      variant: 'light',
      Provider: ({ children }) => (
        <StylesProvider generateClassName={generateClassName}>
          <UnifiedThemeProvider children={children} theme={devportalThemeV2} />
        </StylesProvider>
      ),
    },
  ],
  featureFlags: [],
});

// custom link
const CustomLink = ({ url, elementType, ...props }: LinkComponentProps) => {
  return elementType === 'router-link' ? (
    <Link to={url} {...props} />
  ) : (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={url} {...props} />
  );
};

const HomePage = () => {
  const { isFlagEnabled, loading } = useTaplytics();

  if (loading) {
    return <Loader dataSize="large" kind="full" loadingMessage="Loading" />;
  }

  return isFlagEnabled(FeatureToggles.DRAGONFLY_FEATURES) ? (
    <RbcHomePage />
  ) : (
    <Navigate to="/uscm/rbc-api-catalog" />
  );
};

// backstage routes
export default app.createRoot(
  <RigTheme>
    <AlertDisplay />
    <OAuthRequestDialog />
    <LinkComponentProvider linkComponent={CustomLink}>
      <AppRouter>
        <Root>
          <FlatRoutes>
            <Route path="/" element={<HomePage />} />
            <Route path="/htn" element={<Router />} />
            <Route
              path="/uscm"
              element={<Navigate to="/uscm/rbc-api-catalog" />}
            />
            <Route
              path="/uscm/rbc-api-catalog"
              element={<RbcApiCatalogPage />}
            />
            <Route path="/uscm/h2h-test" element={<H2HTestPage />} />
            <Route path="/uscm/h2h-overview" element={<H2HOverviewPage />} />
            <Route path="/uscm/h2h-test" element={<H2HTestPage />} />
            <Route path="/uscm/h2h-overview" element={<H2HOverviewPage />} />
            <Route
              path="/uscm/catalog/:namespace/:kind/:name"
              element={<CatalogEntityPage />}
            >
              {entityPage}
            </Route>
            <Route
              path="/uscm/docs/:namespace/:kind/:name/*"
              element={<TechDocsReaderPage />}
            >
              <DocRenderPage />
            </Route>
            {/* uscm end */}
            <Route path="/bfs" element={<BfsHomePage />} />
            <Route path="/catalog" element={<CatalogIndexPage />} />
            <Route path="/docs" element={<TechDocsIndexPage />} />
            <Route path="/api-docs" element={<ApiExplorerPage />} />
            <Route path="/search" element={<SearchPage />}>
              {searchPage}
            </Route>
            <Route path="/catalog-graph" element={<CatalogGraphPage />} />
            <Route path="/uscm/not-allow" element={<NotAllowErrorPage />} />
            <Route path="/lob-catalog" element={<LobCatalogPage />} />
            <Route
              path="/catalog/:namespace/:kind/:name"
              element={<CatalogEntityPage />}
            >
              {entityPage}
            </Route>
            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route
              path="/contact-us/success"
              element={<ContactUsPageConfirmation />}
            />
            <Route path="/doc-render" element={<DocRenderPage />} />
          </FlatRoutes>
        </Root>
      </AppRouter>
    </LinkComponentProvider>
  </RigTheme>,
);
