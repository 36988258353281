/**
 * Colors as defined in RIG. These should not be changed or added to.
 */
export const rbcColors = {
  alertError: '#b91a0e',
  alertInfo: '#456b86',
  alertSuccess: '#097b24',
  alertWarning: '#ffba00',
  apple: '#aaba0a',
  appleDark: '#616f03',
  appleDarkTint1: '#7e9006',
  appleTint1: '#d4dc84',
  appleTint2: '#eaeec2',
  appleTint3: '#f6f8e6',
  beige: '#b8a970',
  beigeDark: '#756738',
  beigeDarkTint1: '#9d8e54',
  beigeTint1: '#dbd4b7',
  beigeTint2: '#eeeadd',
  beigeTint3: '#f8f6f0',
  black: '#000000',
  blackTint1: '#1f1f1f',
  blackTint2: '#1f1f1f87',
  brown: '#906646',
  brownDark: '#543b28',
  brownDarkTint1: '#6a4b33',
  brownTint1: '#e7c3a8',
  brownTint2: '#fde5d2',
  brownTint3: '#fff3ea',
  carbon: '#899299',
  carbonDark: '#50585f',
  carbonDarkTint1: '#6b7782',
  carbonTint1: '#c4c8cc',
  carbonTint2: '#e9eaec',
  carbonTint3: '#f6f6f7',
  darkBlue: '#002750',
  darkBlueTint1: '#003168',
  darkGrey: '#444444',
  darkYellow: '#fbaa26',
  darkYellowTint1: '#ffba00',
  green: '#097b24',
  greenLight: '#D2F0D9',
  greenDark: '#005113',
  grey: '#585858',
  greyLightTint1: '#fafafa',
  greyLightTint2: '#f3f4f5',
  greyLightTint3: '#eaeaea',
  greyLightTint4: '#e0e0e0',
  greyTint1: '#6f6f6f',
  greyTint2: '#919191',
  greyTint3: '#b3b3b3',
  pear: '#d6ce48',
  pearDark: '#726c1a',
  pearDarkTint1: '#978f28',
  pearTint1: '#eae6a4',
  pearTint2: '#f5f3d1',
  pearTint3: '#fbfaec',
  rbcBlue: '#0051a5',
  rbcBlueTint1: '#006ac3',
  rbcBlueTint2: '#73b0e3',
  rbcBlueTint3: '#c3e2fa',
  rbcBlueTint4: '#e3f4ff',
  rbcBlueTint5: '#f5fcff',
  rbcYellow: '#fedf01',
  rbcYellowTint1: '#feef80',
  rbcYellowTint2: '#fff7bf',
  rbcYellowTint3: '#fffce5',
  seaweed: '#588886',
  seaweedDark: '#416866',
  seaweedDarkTint1: '#4c7775',
  seaweedTint1: '#abc3c2',
  seaweedTint2: '#e3ebeb',
  seaweedTint3: '#f2f6f6',
  sky: '#51b5e0',
  skyDark: '#236e9a',
  skyDarkTint1: '#3589bb',
  skyTint1: '#a8daef',
  skyTint2: '#d3ecf7',
  skyTint3: '#edf7fc',
  sun: '#fca311',
  sunDark: '#9e5406',
  sunDarkTint1: '#cc700f',
  sunTint1: '#fdd188',
  sunTint2: '#fee8c3',
  sunTint3: '#fff6e7',
  teal: '#00aeb5',
  tealDark: '#0d6a6e',
  tealDarkTint1: '#088c91',
  tealTint1: '#88dde0',
  tealTint2: '#b9f8fa',
  tealTint3: '#e5feff',
  transparent: '#fff0',
  tundra: '#87afbf',
  tundraDark: '#466c86',
  tundraDarkTint1: '#6595a9',
  tundraTint1: '#c3d7df',
  tundraTint2: '#e1ebef',
  tundraTint3: '#f3f7f8',
  violet: '#493d63',
  violetDark: '#1d1934',
  violetDarkTint1: '#2e2851',
  violetTint1: '#c5a5da',
  violetTint2: '#e3d3f4',
  violetTint3: '#f6f1fc',
  warmGrey: '#c1b5a5',
  warmGreyDark: '#70665b',
  warmGreyDarkTint1: '#998e7d',
  warmGreyTint1: '#e0dad2',
  warmGreyTint2: '#efece8',
  warmGreyTint3: '#f9f7f6',
  warmRed: '#f93f26',
  warmRedDark: '#b91a0e',
  warmRedDarkTint1: '#d32619',
  warmRedTint1: '#fc9f92',
  warmRedTint2: '#fdcfc9',
  warmRedTint3: '#feebe9',
  white: '#ffffff',
  wine: '#8b214b',
  wineDark: '#45111a',
  wineDarkTint1: '#6e1a3b',
  wineTint1: '#f39ec0',
  wineTint2: '#f6cddd',
  wineTint3: '#ffebf7',
  offWhite: '#f0f0f0',
  redError: '#9D150B',
};

/**
 * Color styles as defined in RIG. These should not be changed or added to.
 */
export const rbcColorStyles = {
  interactive1: rbcColors.rbcBlueTint1,
  interactive1Hover: rbcColors.rbcBlue,
  interactive1Active: rbcColors.darkBlueTint1,
  interactive1Text: rbcColors.white,
  interactiveAccent: rbcColors.rbcYellow,
  interactiveAccentHover: rbcColors.darkYellowTint1,
  interactiveAccentActive: rbcColors.darkYellow,
  interactiveAccentText: rbcColors.blackTint1,
  interactiveOpposite: rbcColors.rbcYellow,
  interactiveOppositeHover: rbcColors.darkYellowTint1,
  interactiveOppositeActive: rbcColors.darkYellow,
  interactiveOppositeText: rbcColors.blackTint1,
  link: rbcColors.rbcBlueTint1,
  linkHover: rbcColors.rbcBlue,
  linkActive: rbcColors.darkBlueTint1,
  linkOpposite: rbcColors.white,
  linkOppositeHover: rbcColors.white,
  linkOppositeActive: rbcColors.white,

  textDefault: rbcColors.blackTint1,
  textDisabled: rbcColors.greyTint2,
  textLowEmphasis: rbcColors.greyTint1,
  textOpposite1: rbcColors.white,
  textOpposite2: rbcColors.white,

  background1: rbcColors.white,
  background2: rbcColors.greyLightTint1,
  background3: rbcColors.greyLightTint2,
  backgroundDisabled: rbcColors.greyLightTint3,
  backgroundHighlight: rbcColors.tundraTint3,
  backgroundOpposite1: rbcColors.rbcBlueTint1,
  backgroundOverlay: `${rbcColors.blackTint1}80`,
  backgroundOverlayInline: `${rbcColors.white}CC`,
  backgroundPage: rbcColors.white,

  border1: rbcColors.greyTint1,
  border2: rbcColors.blackTint1,
  border3: rbcColors.greyTint3,
  border4: rbcColors.greyLightTint4,
  borderOpposite1: rbcColors.white,

  componentBg1: rbcColors.white,
  componentBg2: rbcColors.skyTint3,
  componentBg3: rbcColors.tundraTint2,
  componentBgOpposite1: rbcColors.transparent,

  selected1: rbcColors.rbcBlueTint1,
  selected2: rbcColors.rbcBlueTint4,
  selectedOpposite1: rbcColors.white,

  actionSuccess: rbcColors.alertSuccess,
  actionError: rbcColors.alertError,
  actionWarning: rbcColors.alertWarning,
  actionInformation: rbcColors.alertInfo,
  actionNeutral: rbcColors.greyLightTint4,
  actionOppositeSuccess: rbcColors.transparent,
  actionOppositeError: rbcColors.transparent,
  actionOppositeWarning: rbcColors.transparent,
  actionOppositeInformation: rbcColors.transparent,
  actionOppositeNeutral: rbcColors.transparent,
};
