import { TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { ContactPageLayoutBlock } from '../ContactUsPage';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(6),
    maxWidth: '600px',
  },
}));

const ContactUsPageConfirmation = () => {
  const classes = useStyles();

  return (
    <ContactPageLayoutBlock paddingYDesktop={0} paddingYMobile={0}>
      <div className={classes.container}>
        <TextStyle kind="heading" elementType="h1">
          Confirmation
        </TextStyle>
        <TextStyle kind="paragraph">
          Page description text area set to a maximum width of 600px to conform
          to optimal line length for ui-sm text. 45 to 80 characters (per line)
          is the ideal line length for text on websites.
        </TextStyle>
      </div>
    </ContactPageLayoutBlock>
  );
};

export default ContactUsPageConfirmation;
