import { ProductNavigation } from '@rig/rw-core-components';
import React from 'react';

function HtnNav() {
  return (
    <ProductNavigation
      destinations={[
        { label: 'Hack The North', src: '/htn', destinationId: 'htn' },
      ]}
      selectedDestination="htn"
    />
  );
}

export default HtnNav;
