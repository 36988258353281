import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import welcomeBannerImg from '../../../assets/welcome-banner-img.png';

const useStyles = makeStyles(theme => {
  return {
    root: {
      backgroundImage: `url(${welcomeBannerImg})`,
      backgroundRepeat: 'no-repeat',
      width: '600px',
      height: '400px',
      flex: 'none',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        backgroundPositionX: 'center',
      },
    },
  };
});

const WelcomeBannerImage = () => {
  const classes = useStyles();

  return <Box className={classes.root} />;
};

export default WelcomeBannerImage;
