import React from 'react';
import { CTAText, TextStyle } from '@rig/rw-core-components';
import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@internal/shared';

const useStyles = makeStyles(theme => ({
  tab: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  tabsTitle: {
    ...fonts.rbcLegacy.heading,
    marginTop: theme.spacing(4),
  },
  list: {
    listStyleType: 'disc',
    marginLeft: theme.spacing(4),
  },
  listItem: {
    fontSize: '16px',
    display: 'list-item',
    fontWeight: 'lighter',
  },
  iconCta: {
    marginTop: theme.spacing(2),
  },
}));

const BuildTabSection = ({ title }: { title: string }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.tab}>
        <div className={classes.tabsTitle}>
          <TextStyle kind="heading">{title}</TextStyle>
        </div>

        <TextStyle kind="paragraph">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce euismod
          velit eget venenatis porttitor.
        </TextStyle>

        <ul className={classes.list}>
          <li className={classes.listItem}>Lorem ipsum dolor sit amet</li>
          <li className={classes.listItem}>consectetur adipiscing elit</li>
          <li className={classes.listItem}>Proin facilisis dolor non</li>
          <li className={classes.listItem}>Proin facilisis dolor non</li>
          <li className={classes.listItem}>Proin facilisis dolor non</li>
        </ul>

        <div className={classes.iconCta}>
          <CTAText onClick={() => {}} icon="search">
            Explore APIs
          </CTAText>
        </div>
      </div>
    </div>
  );
};

export default BuildTabSection;
