/*
 * Copyright 2023 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */ import ReactGA from 'react-ga4';

function gaGetCookie(name: string) {
  // eslint-disable-next-line prefer-template
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0)
      return decodeURIComponent(c.substring(nameEQ.length, c.length)).trim();
  }
  return null;
}

export function gaConfigMain(gaConfigId: string) {
  const insRoleId = gaGetCookie('rbcirole');
  const cbRoleId = gaGetCookie('RBC_CB_RoleId');
  const regionValueCookie = gaGetCookie('RBC_CB_RegionValue');
  const mgr = gaGetCookie('ga-intra-directReports');
  const empType = gaGetCookie('ga-intra-identityType');
  const bu = gaGetCookie('ga-intra-hrPlatformDesc');
  const bufugu = gaGetCookie('ga-intra-bufuguDesc');
  const country = gaGetCookie('ga-intra-country');
  const city = gaGetCookie('ga-intra-city');
  const province = gaGetCookie('ga-intra-province');
  const empId = gaGetCookie('ga-intra-empId');

  const referrer = document.referrer;
  const domain =
    window.location.search.split('domain=')[1] ||
    localStorage.getItem('domainHash');

  ReactGA.gtag('set', 'user_properties', {
    isManager: mgr,
    employeeType: empType,
    businessUnit: bu,
    bufugu: bufugu,
    country: country,
    city: city,
    province: province,
    cbRoleLabel: gaGetCookie('RBC_CB_RoleName'),
    cbRoleNumber: cbRoleId,
    cbRegionValue: regionValueCookie,
    insRoleLabel: gaGetCookie('RBC_Ins_RoleName'),
    insRoleNumber: insRoleId,
    user_Id: empId,
    referrer: referrer,
    domainHash: domain,
  });

  ReactGA.gtag('config', gaConfigId, {
    send_page_view: true,
    user_id: empId,
  });
}
