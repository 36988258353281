import { useLocation } from 'react-router-dom';
import { LOB } from '../models';

const pathToLobMap: { [key: string]: LOB } = {
  '': LOB.RBC,
  'uscm-resources': LOB.USCM,
  'rbc-api-catalog': LOB.USCM,
  docs: LOB.USCM,
  'h2h-test': LOB.USCM,
  'h2h-overview': LOB.USCM,
  'not-allow': LOB.USCM,
};

/**
 * Returns the current LOB (line of business) if it exists in the URL or in the map of pages
 * that belong to certain LOBs.
 * If we are on a shared page, returns `undefined`.
 *
 * Examples:
 * - `localhost:3000/` returns `rbc`.
 * - `localhost:3000/uscm` returns `uscm`.
 * - `localhost:3000/rbc-api-catalog` returns `undefined`.
 */
export const useLineOfBusiness = () => {
  const pathpart = useLocation().pathname.split('/')[1];

  if (Object.values(LOB).includes(pathpart as LOB)) {
    return pathpart as LOB;
  }

  if (Object.keys(pathToLobMap).includes(pathpart)) {
    return pathToLobMap[pathpart];
  }

  return undefined;
};
