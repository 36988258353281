import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { LOB, useIsUscmPhaseOneEnabled } from '@internal/shared';
import useAsyncRetry from 'react-use/lib/useAsyncRetry';

export const entityFields = [
  'kind, metadata.description, metadata.name, metadata.title, metadata.tags, spec.type, spec.version, spec.tag',
];

export interface FilterProps {
  tags?: LOB[];
  specTypes?: string[];
}

export const useGetApiCatalog = (props?: FilterProps) => {
  const { tags, specTypes } = props ?? {};
  const isUscmPhaseOneEnabled = useIsUscmPhaseOneEnabled();
  const catalogApi = useApi(catalogApiRef);
  // We are defaulting to 'uscm' instead of using useLineOfBusiness() until we have a way of
  // showing the LOB in the URl or query params.
  const lob = 'uscm';
  const tagsFilter = tags ?? [lob];

  let specTypeFilter = isUscmPhaseOneEnabled ? ['h2h'] : ['h2h', 'openapi'];

  if (specTypes?.length) {
    specTypeFilter = specTypes;
  }

  const data = useAsyncRetry(async () => {
    const response = await catalogApi.getEntities({
      filter: [{ 'spec.type': specTypeFilter, 'metadata.tags': tagsFilter }],
      fields: entityFields,
    });
    return response.items;
  }, [isUscmPhaseOneEnabled]);

  return data;
};
