import { rbcColors } from '@internal/shared';
import makeStyles from '@mui/styles/makeStyles';
import { BoxContainer } from '@rig/rw-core-components';
import React, { ReactNode } from 'react';

const useStyles = (
  paddingYDesktop = 104,
  paddingYMobile = 40,
  maxWidth = 1120,
) =>
  makeStyles(theme => {
    return {
      outerContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: `${paddingYDesktop}px ${theme.spacing(3)}`,
        zIndex: 1,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          padding: `${paddingYMobile}px 20px`,
        },
      },
      innerContainer: {
        width: '100%',
        maxWidth,
        backgroundColor: rbcColors.transparent,
        padding: theme.spacing(0),
      },
    };
  });

export interface LayoutBlockProps {
  alternateBackground?: boolean;
  backgroundColor?: string;
  paddingYDesktop?: number;
  paddingYMobile?: number;
  children?: ReactNode;
  maxWidth?: number;
}

const LayoutBlock = ({
  alternateBackground,
  backgroundColor,
  paddingYDesktop,
  paddingYMobile,
  children,
  maxWidth,
}: LayoutBlockProps) => {
  const classes = useStyles(paddingYDesktop, paddingYMobile, maxWidth)();
  const background = alternateBackground ? 'alternate' : 'primary';

  return (
    <BoxContainer
      className={classes.outerContainer}
      background={background}
      style={{ backgroundColor }}
    >
      <BoxContainer
        className={classes.innerContainer}
        background={background}
        style={{ backgroundColor }}
      >
        {children}
      </BoxContainer>
    </BoxContainer>
  );
};

export default LayoutBlock;
