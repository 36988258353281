import { Box, Typography } from "@material-ui/core";
import { TextParagraph } from "@rig/rw-core-components";
import { useNavigate } from "react-router";
import HtnTile from "./components/HtnTile";
import React from "react";

const HtnCatalog = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: '40px',
      }}
    >
      <Box>
        <Typography
          variant="h2"
          style={{
            fontWeight: 300,
            fontSize: '28px',
          }}
        >
          API Catalog
        </Typography>
        <TextParagraph>
          A directory that lists and organizes APIs available. It serves as a
          comprehensive resource for developers, providing essential information
          about each API, including its purpose, usage, documentation, and
          metadata.
        </TextParagraph>
      </Box>
      <Box>
        <Typography
          variant="h2"
          style={{
            fontWeight: 300,
            fontSize: '24px',
          }}
        >
          Catalog
        </Typography>
        <HtnTile
          onClick={() => {
            navigate('./entity');
          }}
        />
      </Box>
    </Box>
  );
};

export default HtnCatalog;