import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, TabPanel, TextStyle } from '@rig/rw-core-components';
import { Tabs } from '@rig/rw-core-components';
import { fonts } from '@internal/shared';
import BuildTabSection from './BuildTabSection';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  build: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      width: 'auto',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'center',
      flexDirection: 'column',
      height: theme.spacing(47),
      width: theme.spacing(56),
      padding: theme.spacing(0, 4),
      gap: theme.spacing(2),
    },
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  title: {
    ...fonts.rbcLegacy.heading,
    color: 'white',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  titleBody: {
    color: 'white',
  },
  tabsTitle: {
    ...fonts.rbcLegacy.heading,
    marginTop: theme.spacing(4),
  },
}));

const Build = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.build}>
        <TextStyle kind="heading" className={classes.title}>
          Build
        </TextStyle>
        <TextStyle kind="paragraph" className={classes.titleBody}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce euismod
          velit eget venenatis porttitor. Nulla in lacus ex. Mauris magna neque,
          tincidunt quis ex nec, pretium tincidunt dui.
        </TextStyle>
      </div>
      <div>
        <Tabs>
          <Tab label="API Playground" tabId="0" />
          <Tab label="OAuth Testing Tool" tabId="1" />
          <Tab label="Code Samples" tabId="2" />

          <TabPanel tabId="0">
            <BuildTabSection title="API Playground allows you to easily test your APIs" />
          </TabPanel>

          <TabPanel tabId="1">
            <BuildTabSection title="OAuth Testing Tool" />
          </TabPanel>

          <TabPanel tabId="2">
            <BuildTabSection title="Code Samples" />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Build;
