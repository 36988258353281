import { fonts, rbcColors } from '@internal/shared';
import { makeStyles } from '@material-ui/core';
import { CTAText, TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import UseCasesSectionImage from './UseCasesSectionImage';

const useStyles = makeStyles(theme => {
  return {
    list: {
      listStyleType: 'disc',
      marginLeft: theme.spacing(4),
    },
    info: {
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
      },
    },
    infoImage: {
      display: 'flex',
      gap: theme.spacing(2.5),
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    sectionTitle: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    subTitle: {
      marginBottom: theme.spacing(2),
      color: rbcColors.rbcBlueTint1,
      fontSize: '28px',
      ...fonts.rbcLegacy.heading,
    },
    paragraphInfo: {
      fontSize: '16px',
      marginBottom: theme.spacing(2),
    },
    listItem: {
      fontSize: '16px',
      display: 'list-item',
      fontWeight: 'lighter',
    },
    readMoreCta: {
      marginTop: theme.spacing(2),
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        height: theme.spacing(9),
        marginBottom: theme.spacing(4),
      },
    },
    titleCTAText: {
      fontSize: '16px',
    },
  };
});

interface Props {}

const UseCasesSection = ({}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.titleContainer}>
        <TextStyle
          kind="heading"
          size="md"
          elementType="h2"
          className={classes.sectionTitle}
        >
          Read Our Use Cases
        </TextStyle>
        <CTAText
          elementType="button"
          density="comfortable"
          onClick={() => navigate('/rbc-api-catalog')}
          destinationType="indicator"
        >
          <TextStyle
            kind="heading"
            size="lg"
            elementType="h3"
            className={classes.titleCTAText}
          >
            View All Use Cases
          </TextStyle>
        </CTAText>
      </div>
      <div className={classes.infoImage}>
        <UseCasesSectionImage />
        <div className={classes.info}>
          <TextStyle
            kind="heading"
            size="md"
            elementType="h2"
            className={classes.subTitle}
          >
            Paperless loan application at your fingertips
          </TextStyle>
          <TextStyle
            kind="paragraph"
            size="sm"
            elementType="p"
            className={classes.paragraphInfo}
          >
            You can use a payment request to get your invoices paid, but also
            for payments on location, very convenient!
          </TextStyle>
          <ul className={classes.list}>
            <li className={classes.listItem}>Lorem ipsum dolor sit amet</li>
            <li className={classes.listItem}>consectetur adipiscing elit</li>
            <li className={classes.listItem}>Proin facilisis dolor non</li>
          </ul>
          <div className={classes.readMoreCta}>
            <CTAText
              elementType="button"
              density="comfortable"
              onClick={() => navigate('/rbc-api-catalog')}
              destinationType="indicator"
            >
              <TextStyle kind="paragraph" size="lg" elementType="p">
                Read More
              </TextStyle>
            </CTAText>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseCasesSection;
