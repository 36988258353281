// TODO: the type shouldnt come from the backend, it should be defined in the frontend
// import { ContactUsApiData } from '@internal/backstage-plugin-contactusapi-backend';
import { discoveryApiRef } from '@backstage/core-plugin-api';
import { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';

// GPT-4 written, July 8th 2024
export const useSendContactInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const discoveryApi = useApi(discoveryApiRef);

  const sendContactInfo = async (contactDetails: any) => {
    const baseUrl = await discoveryApi.getBaseUrl('contactusapi');
    setIsLoading(true);

    try {
      const response = await fetch(`${baseUrl}/registerContact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contactDetails }),
      });

      if (!response.ok) {
        throw new Error('Failed to send contact info');
      } else {
        return true;
      }
    } catch (err) {
      setError(err as Error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { sendContactInfo, isLoading, error };
};

export default useSendContactInfo;
