import { LOB, mapLOBToThemeForAppBar } from '@internal/shared';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicIcon, { DynamicIconPosition } from '../../../Root/DynamicIcon';
import WorkingCapitalSolutionsIcon from '../../../Root/WorkingCapitalSolutionsIcon';

const useStyles = (lineOfBus: LOB) =>
  makeStyles(() => mapLOBToThemeForAppBar(lineOfBus));

export interface LineOfBusinessAppBarProps {
  lob?: LOB;
}

const LineOfBusinessAppBar = (props: LineOfBusinessAppBarProps) => {
  const classes = useStyles(props.lob ?? LOB.USCM)();
  const navigate = useNavigate();

  return (
    <Box className={classes.appBarContainer} role="banner">
      <Grid item xs={12} className={classes.topNav}>
        <ButtonBase
          className={classes.topNavItems}
          aria-label="RBC logo icon"
          onClick={() => navigate('/uscm')}
          tabIndex={0}
          role="button"
        >
          <DynamicIcon iconPosition={DynamicIconPosition.left} />
        </ButtonBase>
        <DynamicIcon iconPosition={DynamicIconPosition.center} />
        <WorkingCapitalSolutionsIcon />
      </Grid>
    </Box>
  );
};

export default LineOfBusinessAppBar;
