import { Box, Card, CardContent, makeStyles } from '@material-ui/core';
import { HeadingLevel, HeadingUseCase, ParagraphUseCase } from '@rig/common';
import { CTA, TextHeading, TextParagraph } from '@rig/rw-core-components';
import React from 'react';
import { rbcColors, uscmPalette } from '../../themes';

const useStyles = makeStyles({
  heading: {
    color: rbcColors.black,
    fontSize: '28px',
    fontWeight: 400,
    marginBottom: '2px',
    fontFamily: 'RBC MS Display',
  },
  subHeading: {
    color: uscmPalette.text.medium,
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
  },
  subPara: {
    color: uscmPalette.text.medium,
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: '20px',
  },
  infoCardContainer: {
    boxShadow: 'none',
    background: 'none',
    '@media only screen and (min-width: 1350px)': {
      maxWidth: '582px',
    },
  },
});

type InfoCardType = {
  heading: string;
  subHeading: string;
  body: string;
  btnLabel: string;
  onClick: () => void;
};

function InfoCardWithButton({
  heading,
  subHeading,
  body,
  btnLabel,
  onClick,
}: InfoCardType) {
  const classes = useStyles();

  return (
    <Card className={classes.infoCardContainer}>
      <CardContent style={{ paddingTop: '0' }}>
        <TextHeading
          useCase={HeadingUseCase.noticeHeading}
          className={classes.heading}
          headingLevel={HeadingLevel.h1}
        >
          {heading}
        </TextHeading>
        <TextParagraph
          useCase={ParagraphUseCase.default}
          className={classes.subHeading}
        >
          {subHeading}
        </TextParagraph>
        <TextParagraph
          useCase={ParagraphUseCase.default}
          className={classes.subPara}
        >
          {body}
        </TextParagraph>
        <Box className="catalog-btn">
          <CTA priority="secondary" size="small" onClick={onClick}>
            {btnLabel}
          </CTA>
        </Box>
      </CardContent>
    </Card>
  );
}

export default InfoCardWithButton;
