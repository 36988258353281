import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextStyle } from '@rig/rw-core-components';
import { fonts, rbcColors } from '@internal/shared';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
    },
  },
  box1: {
    backgroundColor: rbcColors.greyLightTint2,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    padding: theme.spacing(5),
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '30%',
      padding: theme.spacing(2),
    },
  },
  boxTitle: {
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18pt',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '15pt',
      textAlign: 'center',
      paddingTop: theme.spacing(1),
    },
  },
  boxTitle2: {
    ...fonts.rbcLegacy.heading,
    fontWeight: 500,
    fontSize: '14pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12pt',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '13pt',
    },
  },
  box2: {
    backgroundColor: rbcColors.blackTint1,
    opacity: '96%',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  codeContainer: {
    padding: theme.spacing(1),
    fontFamily: 'monospace',
    fontSize: '11pt',
    height: '100%',
    width: '100%',
    textAlign: 'left',
    color: rbcColors.greyLightTint4,
    opacity: '90%',
    overflow: 'hidden',
  },
  lineNumber: {
    color: rbcColors.greyTint1,
    marginRight: theme.spacing(1),
  },
  codeLine: {
    marginBottom: '1.5px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0),
    },
  },
  indentLine: {
    marginLeft: theme.spacing(1),
  },
  codeDivContainer: {
    display: 'flex',
  },
  interac: {
    fontStyle: 'italic',
    fontWeight: 100,
    display: 'inline',
  },
}));

// GPT-4o written, Aug 29th 2024
const codeString = `
{
  "amount": 1.00,
  "to_account": {
    "id": "api_test@rbc.com",
    "id_type": "EMAIL_ADDRESS"
  },
  "from_account": {
    "id": "test account",
    "id_type": "ACCOUNT_IDENTIFIER"
  },
  "payment_type": "INTERAC"
}'
//* The response is equally simple, a payment ID is returned to you so you can track the payment, as well as the payment status.*//
{
    "payment_id": "34aefc70-a00a-451a-9c24-fe6033535129",
    "status": "PROCESSED"
}
`;

const CodeSample = () => {
  const classes = useStyles();

  // GPT-4o written, Sept 3rd 2024
  const renderLineNumbers = (code: string) => {
    const { lineNumber, codeLine, indentLine, codeDivContainer } = classes;
    return code
      .split('\n')
      .slice(1, -1)
      .map((line, index) => (
        <div key={index + 1} className={codeDivContainer}>
          <Typography className={lineNumber}>{index + 1}</Typography>
          <Typography
            className={`${codeLine} ${
              line.trim().startsWith('"') ? indentLine : ''
            }`}
          >
            {line}
          </Typography>
        </div>
      ));
  };

  return (
    <div className={classes.root} role="presentation" aria-label="root">
      <div className={classes.box1} role="presentation" aria-label="box1">
        <div>
          <TextStyle
            kind="heading"
            elementType="h2"
            className={classes.boxTitle}
          >
            View a Code Sample
          </TextStyle>
        </div>
        <div>
          <TextStyle
            kind="heading"
            elementType="h3"
            className={classes.boxTitle2}
          >
            Our APIs are designed to be very simple and easy to use. Take a look
            at the CURL code sample.
          </TextStyle>
        </div>
        <div>
          <TextStyle kind="paragraph" elementType="p">
            Send an{' '}
            <Typography className={classes.interac} component="span">
              Interac
            </Typography>{' '}
            payment using our payment API is as simple as sending a POST request
            to our /payments endpoint with a request that looks like this:
          </TextStyle>
        </div>
      </div>
      <div className={classes.box2} role="presentation" aria-label="box2">
        <TextStyle
          kind="ui"
          elementType="div"
          className={classes.codeContainer}
        >
          {renderLineNumbers(codeString)}
        </TextStyle>
      </div>
    </div>
  );
};

export default CodeSample;
