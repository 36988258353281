import { rbcColors, uscmPalette } from '@internal/shared';
import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { HeadingLevel, HeadingUseCase, ParagraphUseCase } from '@rig/common';
import { CTA, TextHeading, TextParagraph } from '@rig/rw-core-components';
import { useNavigate } from 'react-router-dom';
import particles from '../../../assets/particles.png';

const useStyles = makeStyles({
  bannerOuterContainer: {
    width: '100%',
    backgroundImage: `url(${particles})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: uscmPalette.primary.main,
    '@media only screen and (max-width: 1026px)': {
      display: 'none',
    },
  },
  bannerContainer: {
    width: '100%',
    maxWidth: '1440px',
    margin: 'auto',
    padding: '1rem 3rem 2rem',
    flexDirection: 'row-reverse',
  },
  bannerLogoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    order: 1,
  },
  bannerLogo: {
    margin: '0 2rem',
    maxHeight: '225px',
    height: 'auto',
  },
  bannerText: {
    color: uscmPalette.background.main,
    width: '100vw',
    order: 2,
  },
  bannerTitle: {
    fontSize: '36px',
    width: 'auto',
    maxWidth: '400px',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  bannerSubtitle: {
    maxWidth: '1000px',
    fontSize: '18px',
  },
  bannerButton: {
    marginTop: '1.5rem',
    backgroundColor: uscmPalette.interactive.main,
    color: uscmPalette.text.main,
    border: `1px solid ${rbcColors.white}`,
  },
  bannerButtonText: {
    fontSize: '18px',
  },
});
const Banner = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.bannerOuterContainer}>
      <Grid container className={classes.bannerContainer}>
        <Grid item xs={12} lg={8} className={classes.bannerText}>
          <Grid item>
            <TextHeading
              useCase={HeadingUseCase.noticeHeading}
              headingLevel={HeadingLevel.h1}
              className={classes.bannerTitle}
            >
              Welcome to the RBC USCM Developer Portal
            </TextHeading>
            <TextParagraph
              useCase={ParagraphUseCase.default}
              className={classes.bannerSubtitle}
            >
              To unlock the full potential of our platform, we invite you to
              sign up and join our community. Gain access to a wealth of
              resources that will empower you to build innovative solutions.
              Explore our catalog of diverse APIs and Host-to-Host capabilities
              to integrate with your own ideas to unlock a world of
              possibilities.
            </TextParagraph>
          </Grid>
          <Grid item>
            <CTA
              priority="accent"
              className={classes.bannerButton}
              size="small"
              onClick={() => navigate('/catalog')}
            >
              <TextHeading
                useCase={HeadingUseCase.noticeHeading}
                headingLevel={HeadingLevel.h5}
                className={classes.bannerButtonText}
              >
                Get Started
              </TextHeading>
            </CTA>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={4}
          className={classes.bannerLogoContainer}
        >
          <img
            className={classes.bannerLogo}
            src={require('../../../assets/banner-logo.png')}
            alt="rbc-logo"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Banner;
