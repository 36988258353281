import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsyncRetry from 'react-use/lib/useAsyncRetry';

export const useGetApiCatalogEntity = (apiName: string) => {
  const catalogApi = useApi(catalogApiRef);
  const data = useAsyncRetry(async () => {
    const items = await catalogApi.getEntityByRef({
      kind: 'API',
      namespace: 'default',
      name: apiName,
    });
    return items;
  }, [catalogApi]);
  return data;
};
