import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { CTAText, RBCIcon } from '@rig/rw-core-components';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { rbcColors, uscmColors, uscmPalette } from '../../../themes';

const useStyles = makeStyles({
  footerContainer: {
    backgroundColor: uscmColors.uscmBlue,
    fontFamily: 'Roboto',
  },
  linksContainer: {
    display: 'flex',
    margin: '0.2rem',
  },
  linkText: {
    paddingRight: '0.7rem',
    color: 'white',
  },
  divider: {
    backgroundColor: rbcColors.white,
    maxHeight: '12px',
    alignSelf: 'center',
  },
  linkLabel: {
    fontSize: '16px',
    fontWeight: 400,
    fontStyle: 'normal',
    color: uscmPalette.background.main,
    paddingRight: '8px',
    lineHeight: '24px',
  },
  linkBtn: {
    color: `${uscmPalette.background.main} !important`,
  },
  footerSubText: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
  },
  footerLinks: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividerBox: {
    background: uscmPalette.border.main,
    margin: '0 2px 0 8px',
  },
});

// TODO: Add footer links
const footerLinks = [
  { text: 'Legal', path: 'https://www.rbc.com/legal' },
  {
    text: 'Accessibility',
    path: 'https://www.rbc.com/accessibility/index.html',
  },
  {
    text: 'Privacy & Security',
    path: 'https://www.rbc.com/privacysecurity/ca/online-privacy.html',
  },
];

const LineOfBusinessFooter = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      role="contentinfo"
      className={classes.footerContainer}
      sx={{
        height: 'auto',
        color: rbcColors.white,
        padding: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '88px',
        marginTop: pathname.includes('catalog')
          ? theme.spacing(3)
          : theme.spacing(0),
      }}
    >
      <Box
        className={classes.footerContainer}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: '1440px',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '65%',
          }}
        >
          <Typography className={classes.footerSubText}>
            ® / ™ Trademark(s) of Royal Bank of Canada. Royal Bank of Canada
            Website, © {currentYear}.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {footerLinks.map((link, index) => {
            return (
              <Grid item key={link.text} className={classes.linksContainer}>
                <Box className={classes.footerLinks}>
                  <CTAText
                    elementType="link"
                    url={link.path}
                    srLabel={`For reading more information about ${link.text}`}
                  >
                    <Typography className={classes.linkLabel}>
                      {link.text}
                    </Typography>
                  </CTAText>
                  <RBCIcon
                    decorative
                    name="external-link"
                    size="s"
                    srLabel="external-link"
                    useSprites
                  />
                  {index !== footerLinks.length - 1 && (
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      className={classes.dividerBox}
                    />
                  )}
                </Box>
              </Grid>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default LineOfBusinessFooter;
