import { TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { rbcColors } from '@internal/shared';
import Typography from '@mui/material/Typography';
import Accordion from './accordion';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: 'center',
    fontSize: '28pt',
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '22pt',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18pt',
    },
  },
  paragraph: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  boxContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  secondBoxContainer: {
    marginTop: theme.spacing(3),
    border: '1px solid',
    borderColor: rbcColors.greyLightTint4,
  },
  boxTitleContainer: {
    backgroundColor: rbcColors.greyLightTint1,
    padding: theme.spacing(3),
    textAlign: 'center',
    borderRadius: '8px 8px 0 0',
  },
  boxTitleContainerNoColor: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  boxBodyThird: {
    padding: theme.spacing(2),
  },
  boxTitle: {
    fontWeight: 500,
    fontSize: '20pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18pt',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16pt',
    },
  },
  boxBody: {
    margin: '24px 0px 50px 0px',
    padding: theme.spacing(3, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 3),
    },
  },
  dropdown: {
    padding: theme.spacing(2),
    display: 'block',
    position: 'relative',
  },
  box: {
    borderRadius: '8px',
    width: '50%',
    height: 'fit-content',
    border: '1px solid',
    borderColor: rbcColors.greyLightTint4,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  boldText: {
    fontWeight: 500,
    display: 'inline',
  },
  listItem: {
    marginTop: theme.spacing(1),
  },
  list: {
    listStyleType: 'decimal',
    width: '90%',
    marginLeft: theme.spacing(4),
  },
}));

const ExploreApis = () => {
  const classes = useStyles();
  const title1 =
    '<span class="firstPart" style="font-style: italic;">How it</span> <span class="secondPart" style="font-weight: 500;">Works</span>';
  const title2 =
    '<span class="firstPart" style="font-style: italic;">How You Can</span> <span class="secondPart" style="font-weight: 500;">Benefit</span> <span class="firstPart" style="font-style: italic;">from it</span>';
  return (
    <div className="flex-container">
      <TextStyle elementType="h2" kind="heading" className={classes.heading}>
        Explore the APIs Available to You
      </TextStyle>
      <TextStyle elementType="p" kind="paragraph" className={classes.paragraph}>
        You don’t have to choose just one.
      </TextStyle>
      <div className={classes.boxContainer}>
        <Box className={classes.box}>
          <div className={classes.boxTitleContainer}>
            <TextStyle
              elementType="h3"
              kind="heading"
              className={classes.boxTitle}
            >
              Transactional APIs
            </TextStyle>
          </div>
          <div className={classes.boxBody}>
            <TextStyle elementType="div" kind="ui">
              Make payments and transfers seamless for both you and your
              clients. Ask us about{' '}
              <Typography className={classes.boldText}>
                RBC Move Money API via Interac e-Transfer
              </Typography>
              , and
              <Typography className={classes.boldText}>
                {' '}
                RBC Pay API{' '}
              </Typography>
              by reaching out to your RBC relationship manager.
            </TextStyle>
          </div>
          <div className={classes.dropdown}>
            <Accordion title={title1}>
              <ol className={classes.list}>
                <li className={classes.listItem}>
                  <TextStyle elementType="p" kind="paragraph">
                    <Typography className={classes.boldText}>
                      Create a payment order to send money
                    </Typography>{' '}
                    from your account to another company or person
                  </TextStyle>
                </li>
                <li className={classes.listItem}>
                  <TextStyle elementType="p" kind="paragraph">
                    <Typography className={classes.boldText}>
                      {' '}
                      Do you need to send invoice information to a supplier?
                    </Typography>{' '}
                    If so, you can attach enriched remittance information or
                    details of your invoice to the recipient
                  </TextStyle>
                </li>
                <li className={classes.listItem}>
                  <TextStyle elementType="p" kind="paragraph">
                    Ready to send? It’s a{' '}
                    <Typography className={classes.boldText}>
                      real-time payment
                    </Typography>{' '}
                    so your receiving party will have the funds deposited in
                    near real time along with any remittance information.
                  </TextStyle>
                </li>
              </ol>
            </Accordion>
          </div>
        </Box>
        <Box className={classes.box}>
          <div className={classes.boxTitleContainer}>
            <TextStyle
              elementType="h3"
              kind="heading"
              className={classes.boxTitle}
            >
              Informational APIs
            </TextStyle>
          </div>
          <div className={classes.boxBody}>
            <TextStyle elementType="div" kind="ui">
              Easily send and receive real-time notifications, status updates,
              balance and transaction information. Askus about{' '}
              <Typography className={classes.boldText}>
                RBC Balance and Transactions, Tracking and Validation APIs
              </Typography>{' '}
              by reaching out to your RBC relationship manager.
            </TextStyle>
          </div>
          <div className={classes.dropdown}>
            <Accordion title={title2}>
              <ol className={classes.list}>
                <li className={classes.listItem}>
                  <TextStyle elementType="p" kind="paragraph">
                    <Typography className={classes.boldText}>
                      {' '}
                      Real-time access to account information
                    </Typography>{' '}
                    – Gain real-time visibility of cash positions,within your
                    enterprise or treasury management system, regarding payment,
                    deposit settlement and acknowledgement tracked to the
                    originating payment in a few seconds.
                  </TextStyle>
                </li>
                <li className={classes.listItem}>
                  <TextStyle elementType="div" kind="ui">
                    <Typography className={classes.boldText}>
                      Reconciliation and Integrated Working Capital
                    </Typography>{' '}
                    – Access a secure connection between RBC and your enterprise
                    or treasury management system to enable automated end-to-end
                    working capital integration. Improved visibility and
                    efficiency to manage reconciliation activities. Integrating
                    client accounting platforms with the B&T API will give a
                    more complete picture of finances to help provide improved
                    and actionable insights.
                  </TextStyle>
                </li>
                <li className={classes.listItem}>
                  <TextStyle elementType="div" kind="ui">
                    <Typography className={classes.boldText}>
                      Tracking and More
                    </Typography>{' '}
                    – Check the status of wire transfers or validate recipient
                    information before sending funds to improve payment
                    certainty and reduce fraud risk.
                  </TextStyle>
                </li>
              </ol>
            </Accordion>
          </div>
        </Box>
      </div>
      <Box className={classes.secondBoxContainer}>
        <div className={classes.boxTitleContainerNoColor}>
          <TextStyle
            elementType="h3"
            kind="heading"
            className={classes.boxTitle}
          >
            Integration APIs - coming soon
          </TextStyle>
        </div>
        <div className={classes.boxBodyThird}>
          <TextStyle elementType="p" kind="paragraph">
            Connect directly with your third-party payment service provider,
            data aggregators and other partners.
          </TextStyle>
        </div>
      </Box>
    </div>
  );
};

export default ExploreApis;
