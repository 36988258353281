import { rbcColors } from '../rig';

export const uscmColors = {
  uscmBlue: '#02192A',
  uscmBlueTint1: '#26385A',
};

export const uscmPalette = {
  primary: {
    main: rbcColors.rbcBlueTint1,
    light: rbcColors.skyTint3,
    dark: rbcColors.darkBlueTint1,
    alt: rbcColors.rbcBlue,
  },
  secondary: {
    main: rbcColors.greyLightTint4,
    light: rbcColors.greyLightTint3,
    dark: rbcColors.greyTint2,
  },
  warning: {
    main: rbcColors.alertWarning,
    light: rbcColors.rbcYellowTint2,
    dark: rbcColors.darkYellowTint1,
  },
  error: {
    main: rbcColors.alertError,
    light: rbcColors.warmRedTint2,
    dark: rbcColors.warmRedDark,
    offWhite: rbcColors.offWhite,
    redError: rbcColors.redError,
  },
  success: {
    main: rbcColors.alertSuccess,
    light: rbcColors.greenLight,
    dark: rbcColors.greenDark,
  },
  info: {
    main: rbcColors.alertInfo,
    dark: rbcColors.tundraDark,
    light: rbcColors.tundraTint1,
  },
  interactive: {
    main: rbcColors.rbcYellow,
    light: rbcColors.rbcYellow,
    dark: rbcColors.darkYellow,
  },
  status: {
    success: rbcColors.alertSuccess,
    error: rbcColors.alertError,
    warning: rbcColors.alertWarning,
    information: rbcColors.alertInfo,
    neutral: rbcColors.greyLightTint4,
  },
  background: {
    main: rbcColors.white,
    secondary: rbcColors.greyLightTint1,
    alt: rbcColors.greyLightTint2,
    highlight: rbcColors.tundraTint3,
  },
  border: {
    main: rbcColors.greyTint1,
    dark: rbcColors.blackTint1,
    subtle: rbcColors.greyTint3,
    faint: rbcColors.greyLightTint4,
  },
  text: {
    main: rbcColors.blackTint1,
    dark: rbcColors.blackTint1,
    alt: rbcColors.darkGrey,
    medium: rbcColors.grey,
    opposite: rbcColors.white,
  },
  disabled: {
    main: rbcColors.greyLightTint3,
    secondary: rbcColors.grey,
  },
  selected: {
    main: rbcColors.rbcBlueTint1,
    range: rbcColors.rbcBlueTint4,
  },
};
