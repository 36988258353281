import { Box, Typography, makeStyles } from '@material-ui/core';
import { TextStyle } from '@rig/rw-core-components';
import React, { useEffect, useState } from 'react';
import { rbcColors, uscmPalette } from '../../themes';

const useStyles = makeStyles({
  progressBarConatiner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '12px',
  },
  progressBarZone: {
    margin: '0 16px',
    border: `2px dashed ${rbcColors.greyTint2}`,
    width: '98%',
    minHeight: '100px',
    padding: '48px 0',
    backgroundColor: uscmPalette.background.secondary,
    borderRadius: '4px',
  },
  containerStyles: {
    width: '96%',
    backgroundColor: uscmPalette.secondary.main,
  },
  fillerStyles: {
    height: '8px',
    backgroundColor: rbcColors.sky,
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 0.2s ease-in-out',
  },
  labelStyles: {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  },
  headingLabel: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingLeft: '28px',
    paddingBottom: '16px',
  },
});

const UploadProgressBar = ({ fileTitle }: any) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const simulateApiUpload = () => {
    return new Promise(resolve => setTimeout(resolve, 2000));
  };

  useEffect(() => {
    const updateProgressBar = async () => {
      let width = 0;
      while (width < 100) {
        await simulateApiUpload();
        width += Math.floor(Math.random() * 10) + 1; // Add a random amount between 1 and 10
        if (width > 100) width = 100; // Ensure the width doesn't exceed 100
        setProgress(width);
      }
    };
    updateProgressBar();
  }, []);

  return (
    <Box
      className={classes.progressBarZone}
      data-testid="progress-bar-conatiner"
    >
      <TextStyle
        kind="heading"
        size="2xs"
        className={classes.headingLabel}
        data-testid="uploading-file-name"
      >
        Uploading {fileTitle}
      </TextStyle>
      <Box className={classes.progressBarConatiner}>
        <Box className={classes.containerStyles}>
          <Box
            className={classes.fillerStyles}
            style={{ width: `${progress}%` }}
          >
            <Typography className={classes.labelStyles} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadProgressBar;
