import { UnifiedThemeOptions, createUnifiedTheme } from '@backstage/theme';
import { rbcColors, uscmPalette } from '@internal/shared';
import { baseThemeOptions } from './devPortalV2';

const h2hThemeOptions: UnifiedThemeOptions = {
  ...baseThemeOptions,
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: {
          padding: 0,
          boxShadow: 'none',
        },
        type: {
          display: 'none',
        },
        rightItemsBox: {
          display: 'none',
        },
      },
    },
    BackstageContent: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          paddingTop: 0,
          paddingLeft: '2px !important',
        },
      },
    },
    CatalogReactEntityDisplayName: {
      styleOverrides: {
        root: {
          fontFamily: 'RBC MS Display',
          color: rbcColors.black,
          fontSize: '28px',
          fontWeight: 400,
          position: 'absolute',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&[aria-label="favorite"]': {
            display: 'none',
          },
        },
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          padding: '12px 16px',
          textTransform: 'capitalize',
          fontSize: '16px',
          fontWeight: 400,
        },
        selected: {
          color: uscmPalette.primary.main,
        },
        tabsWrapper: {
          marginTop: '28px',
          paddingLeft: 0,
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scroller: {
          backgroundColor: 'white',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    BackstagePage: {
      styleOverrides: {
        root: {
          maxWidth: '1440px',
          margin: 'auto',
          overflowY: 'unset',
          height: '100%',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          display: 'none !important',
        },
      },
    },
  },
};

export const h2hTheme = createUnifiedTheme(h2hThemeOptions);
