export const fonts = {
  primary: {
    main: 'Roboto, sans-serif',
  },
  rbcFuture: {
    heading: {
      fontFamily: '"RBC MS Display", Roboto, Helvetica, sans-serif',
      fontWeight: 400,
    },
  },
  rbcLegacy: {
    heading: {
      fontFamily: 'Roboto, Helvetica, sans-serif',
      fontWeight: 300,
    },
  },
};
