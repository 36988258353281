import { useTaplytics } from '@internal/backstage-plugin-taplytics-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const DOMAIN_HASH_KEY = 'domainHash';
const LAST_SAVED_TIME_KEY = 'lastSavedTime';
const ALLOW_DOMAIN_HASHES_TAPLYTICS_VARIABLE = 'allowedHashes';
const MARKETO_REFERRER_TAPLYTICS_VARIABLE = 'marketoReferrer';

const setLocalStorage = (paramHash: string) => {
  const currentTime = new Date().getTime();
  localStorage.setItem(DOMAIN_HASH_KEY, paramHash);
  localStorage.setItem(LAST_SAVED_TIME_KEY, currentTime.toString());
};

const clearLocalStorage = () => {
  localStorage.removeItem(DOMAIN_HASH_KEY);
  localStorage.removeItem(LAST_SAVED_TIME_KEY);
};

const isDomainHashAllowed = (allowedDomainHashes: string[], dHash: string) => {
  return !!allowedDomainHashes?.find((item: string) => {
    return item && item === dHash;
  });
};

export const useAllowUsers = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const [allowedDomainHashes, setAllowedDomainHashes] = useState<string[]>([]);
  const [allowedReferrer, setAllowedReferrer] = useState('');
  const [isAllowed, setIsAllowed] = useState(false);
  const [domainHash, setDomainHash] = useState('');
  const [localStorageDomainHash, setLocalStorageDomainHash] = useState('');

  const [checkFinished, setCheckFinished] = useState(false);
  const { getVariable } = useTaplytics();

  /**
   * Save to local storage if the user is allowed
   */
  useEffect(() => {
    if (localStorage.getItem(LAST_SAVED_TIME_KEY)) {
      setLocalStorageDomainHash(localStorage.getItem(DOMAIN_HASH_KEY) ?? '');
    }
    const hashes = getVariable(ALLOW_DOMAIN_HASHES_TAPLYTICS_VARIABLE, '');

    if (hashes && hashes.length > 0) {
      JSON.parse(hashes);
      setAllowedDomainHashes(JSON.parse(hashes));
    }

    const referer = getVariable(MARKETO_REFERRER_TAPLYTICS_VARIABLE, '');
    setAllowedReferrer(referer as string);
  }, [getVariable]);

  useEffect(() => {
    if (searchParams.get('domain')) {
      setDomainHash(searchParams.get('domain') ?? '');
    }
  }, [searchParams]);

  /**
   * Check if the user is allowed
   */
  useEffect(() => {
    if (allowedDomainHashes.length === 0 || !allowedReferrer) return;

    const lastSavedTime = localStorage.getItem(LAST_SAVED_TIME_KEY);
    const currentTime = new Date().getTime();
    const isFirstTime = !lastSavedTime;
    const isReferrerAllowd = document.referrer.includes(allowedReferrer);
    const isAllowedDomain =
      domainHash && isDomainHashAllowed(allowedDomainHashes, domainHash);

    const isExpired =
      lastSavedTime && currentTime - parseInt(lastSavedTime, 10) > ONE_DAY_MS;

    if (isFirstTime && isReferrerAllowd && isAllowedDomain) {
      setLocalStorage(domainHash);
      setIsAllowed(true);
      setCheckFinished(true);
      return;
    }

    if (!isFirstTime && domainHash && !isAllowedDomain) {
      setIsAllowed(false);
      clearLocalStorage();
      setCheckFinished(true);
      return;
    }

    if (!isFirstTime && localStorageDomainHash && !isExpired) {
      setIsAllowed(true);
      setCheckFinished(true);
      return;
    }

    setIsAllowed(false);
    clearLocalStorage();
    setCheckFinished(true);
  }, [
    allowedDomainHashes,
    allowedReferrer,
    domainHash,
    localStorageDomainHash,
  ]);

  return { isAllowed, checkFinished };
};
