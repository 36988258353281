import makeStyles from '@mui/styles/makeStyles';
import { TextStyle } from '@rig/rw-core-components';
import React from 'react';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    margin: theme.spacing(5, 0),
  },
  paragraph: {
    maxWidth: '600px',
  },
}));

const ContactUsPageHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <TextStyle kind="heading" elementType="h1">
        Contact a Relationship Manager
      </TextStyle>
      <TextStyle kind="paragraph" className={classes.paragraph}>
        Page description text area set to a maximum width of 600px to conform to
        optimal line length for ui-sm text. 45 to 80 characters (per line) is
        the ideal line length for text on websites.
      </TextStyle>
    </div>
  );
};

export default ContactUsPageHeader;
