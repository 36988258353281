import { LayoutBlock, LayoutBlockProps } from '@internal/shared';
import { Divider } from '@rig/rw-core-components';
import React, { useState } from 'react';
import CommentsWidget from './Comments/CommentsWidget';
import ContactUsPageButtons from './buttons/ContactUsPageButtons';
import ContactInformationWidget from './ContactInformation/ContactInformationWidget';
import ContactUsPageHeader from './header/ContactUsPageHeader';
import { useSendContactInfo } from './hooks/ApiCallHook';
import { useNavigate } from 'react-router';
// TODO: the type shouldnt come from the backend, it should be defined in the frontend
// import { ContactUsApiData } from '@internal/backstage-plugin-contactusapi-backend';

const PAGE_WIDTH = 800;

export const ContactPageLayoutBlock = ({
  children,
  ...props
}: LayoutBlockProps) => (
  <LayoutBlock maxWidth={PAGE_WIDTH} {...props}>
    {children}
  </LayoutBlock>
);

export const ContactUsPage = () => {
  const [nameField, setNameField] = useState<string>('');
  const [contactRadioField, setContactRadioChange] = useState<string>('');
  const [contactField, setContactChange] = useState<string>('');
  const [commentsData, setCommentsData] = useState<string>('');

  const navigate = useNavigate();
  const { sendContactInfo } = useSendContactInfo();

  const handleSubmit = () => {
    const contactDetails = {
      apiId: 'TEST1234',
      contactName: nameField,
      contactEmail: contactRadioField === 'Email' ? contactField : '',
      contactPhone: contactRadioField === 'Phone' ? contactField : '',
      message: commentsData,
    } as any;

    sendContactInfo(contactDetails).then(result => {
      if (result) {
        navigate('/contact-us/success');
      }
    });
  };

  return (
    <>
      <ContactPageLayoutBlock paddingYDesktop={0} paddingYMobile={0}>
        <ContactUsPageHeader />
      </ContactPageLayoutBlock>

      <ContactPageLayoutBlock paddingYDesktop={0} paddingYMobile={0}>
        <Divider />
      </ContactPageLayoutBlock>

      <ContactPageLayoutBlock paddingYDesktop={40}>
        <ContactInformationWidget
          onNameChange={setNameField}
          onContactRadioChange={setContactRadioChange}
          onContactChange={setContactChange}
        />
      </ContactPageLayoutBlock>

      <ContactPageLayoutBlock paddingYDesktop={0} paddingYMobile={0}>
        <Divider />
      </ContactPageLayoutBlock>

      <ContactPageLayoutBlock paddingYDesktop={40}>
        <CommentsWidget onCommentsChange={setCommentsData} />
      </ContactPageLayoutBlock>

      <ContactPageLayoutBlock paddingYDesktop={0} paddingYMobile={0}>
        <ContactUsPageButtons onSubmit={handleSubmit} />
      </ContactPageLayoutBlock>
    </>
  );
};

export default ContactUsPage;
