import { fonts, rbcColors } from '@internal/shared';
import { Box, makeStyles } from '@material-ui/core';
import { RBCIcon, TextParagraph, TextStyle } from '@rig/rw-core-components';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => {
  return {
    header: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(6),
      },
    },
    cardContainer: {
      display: 'flex',
      gap: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    textSection: {
      color: rbcColors.rbcBlueTint1,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      ...fonts.rbcLegacy.heading,
    },
    card: {
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(5),
      },
    },
  };
});

interface Props {}

const GettingStartedBlock = ({}: Props) => {
  const classes = useStyles();

  return (
    <>
      <TextStyle
        kind="heading"
        className={classes.header}
        size="md"
        elementType="h2"
      >
        Take Your Business To The Next Level
      </TextStyle>
      <Box className={classes.cardContainer}>
        <Link to="dummy_getting_started_link">
          <Box className={classes.card}>
            <RBCIcon name="lightbulb" size={64} />
            <TextStyle
              kind="heading"
              className={classes.textSection}
              size="md"
              elementType="h3"
            >
              Getting Started
            </TextStyle>
            <TextParagraph>
              Learn how to get the most out of our APIs and integrate them into
              your business.
            </TextParagraph>
          </Box>
        </Link>
        <Link to="dummy_my_apps_link">
          <Box className={classes.card}>
            <RBCIcon name="list-group" size={64} />
            <TextStyle
              kind="heading"
              className={classes.textSection}
              size="md"
              elementType="h3"
            >
              My Apps
            </TextStyle>
            <TextParagraph>
              Start creating your apps, gather analytics, and see them assembled
              all in one place.
            </TextParagraph>
          </Box>
        </Link>
        <Link to="dummy_faqs_link">
          <Box className={classes.card}>
            <RBCIcon name="faqs" size={64} />
            <TextStyle
              kind="heading"
              className={classes.textSection}
              size="md"
              elementType="h3"
            >
              FAQ
            </TextStyle>
            <TextParagraph>
              Find answers to frequently asked questions.
            </TextParagraph>
          </Box>
        </Link>
      </Box>
    </>
  );
};

export default GettingStartedBlock;
