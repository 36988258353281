import { Box, makeStyles } from '@material-ui/core';
import { CTA, RBCIcon, TextStyle } from '@rig/rw-core-components';
import React from 'react';
import WelcomeBannerImage from './WelcomeBannerImage';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    textSection: {
      marginBottom: theme.spacing(3),
    },
    button: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
    },
    buttonIcon: {
      display: 'flex',
    },
    buttonContent: {
      paddingLeft: theme.spacing(1),
    },
  };
});

const WelcomeBanner = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.textContainer}>
        <TextStyle
          kind="heading"
          size="xl"
          elementType="h1"
          className={classes.textSection}
        >
          Welcome to the <br /> RBC Developer Portal
        </TextStyle>
        <TextStyle
          kind="ui"
          emphasis="low"
          elementType="p"
          className={classes.textSection}
        >
          Explore our catalog of diverse APIs and integrate them with your own
          ideas to unlock a world of possibilities. Transform your ideas into
          apps and strengthen your business with more features with the help of
          our diverse APIs.
        </TextStyle>
        <CTA priority="primary" className={classes.button}>
          <RBCIcon name="play" size="sm" className={classes.buttonIcon} />
          <span className={classes.buttonContent}>Get Started</span>
        </CTA>
      </Box>
      <WelcomeBannerImage />
    </Box>
  );
};

export default WelcomeBanner;
