import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { LOB } from '../models';

export const getStoreOptions = () => ({
  store: 'shared',
  // TODO: fix this, backstage doesnt have access to process.env.NODE_ENV
  // enabled: process.env.NODE_ENV !== 'production',
});

type State = {
  currentLob: LOB;
  filterByLob: LOB;
  featureFlags: {
    loaded: boolean;
    data: Record<string, string>;
  };
};

type Actions = {
  setCurrentLob: (state: LOB) => void;
  setFilterByLob: (state: LOB) => void;
  setFeatureFlags: (state: Record<string, string>) => void;
};

export const useSharedStore = create<State & Actions>()(
  devtools(
    persist(
      set => ({
        currentLob: LOB.RBC,
        filterByLob: LOB.RBC,
        featureFlags: {
          data: {},
          loaded: false,
        },
        setCurrentLob: (currentLob: LOB) =>
          set(
            () => ({
              currentLob,
            }),
            false,
            { type: 'setCurrentLob', currentLob },
          ),
        setFilterByLob: (filterByLob: LOB) =>
          set(state => ({
            ...state,
            filterByLob,
          })),
        setFeatureFlags: (flags: Record<string, string>) =>
          set(
            () => ({
              featureFlags: {
                data: flags,
                loaded: true,
              },
            }),
            false,
            { type: 'setFeatureFlags', flags },
          ),
      }),
      {
        name: 'shared_app_store',
        partialize: state => ({ currentLob: state.currentLob }),
      },
    ),
    getStoreOptions(),
  ),
);
