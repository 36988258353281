import {
  CTAText,
  LayoutContainer,
  TextHeading,
  TextParagraph,
} from '@rig/rw-core-components';
import React from 'react';

import { InfoCardWithButton, uscmPalette } from '@internal/shared';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import makeStyles from '@mui/styles/makeStyles';
import { HeadingLevel, HeadingUseCase, ParagraphUseCase } from '@rig/common';
import { Link, useNavigate } from 'react-router-dom';
import currencyExchange from '../../../assets/currency-exchange.svg';
import errorIconSvg from '../../../assets/errorIcon.svg';
import globeIcon from '../../../assets/globe-icon.svg';
import investmentsSvg from '../../../assets/investments.svg';

const useStyles = makeStyles({
  gridContainer: {
    margin: '40px 0px 36px',
  },
  apiCard: {
    cursor: 'pointer',
    minWidth: '40%',
    maxHeight: '200px',
    minHeight: '120px',
    alignItems: 'flex-start',
    boxShadow:
      '0px 3.59106px 7.84712px 0px rgba(37, 37, 37, 0.08), 0px 12.06161px 26.35684px 0px rgba(37, 37, 37, 0.06), 0px 54px 118px 0px rgba(37, 37, 37, 0.04)',
    '&:hover': {
      backgroundColor: uscmPalette.background.secondary,
    },
  },
  apiIcon: {
    alignSelf: 'center',
  },
  apiLabel: {
    maxWidth: '204px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: uscmPalette.border.main,
    paddingTop: '4px',
    fontFamily: 'Roboto, sans-serif',
  },
  divider: {
    backgroundColor: uscmPalette.border.main,
  },
  apiCardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
  },
  imageIcon: {
    border: `1px solid ${uscmPalette.border.main}`,
    position: 'relative',
    height: '100%',
  },
  imageSvg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  apiDetailsHeader: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: uscmPalette.text.main,
  },
  iconBackgrd: {
    boxShadow:
      '0px 3.59106px 7.84712px 0px rgba(37, 37, 37, 0.08), 0px 12.06161px 26.35684px 0px rgba(37, 37, 37, 0.06), 0px 54px 118px 0px rgba(37, 37, 37, 0.04)',
    padding: '4px 4px 0',
    borderRadius: '50%',
    backgroundColor: uscmPalette.background.secondary,
    marginRight: '24px',
  },
  hostItems: {
    listStyle: 'disc',
    color: uscmPalette.primary.main,
  },
  hostDetsContainer: {
    marginLeft: '32px',
  },
  cardContentConatiner: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '24px',
  },
  cardHover: {
    height: '100%',
    // padding: '12px 8px',
    borderLeft: `4px solid ${uscmPalette.primary.main}`,
    borderRadius: '0',
    boxShadow:
      '0px 3.59106px 7.84712px 0px rgba(37, 37, 37, 0.08), 0px 12.06161px 26.35684px 0px rgba(37, 37, 37, 0.06), 0px 54px 118px 0px rgba(37, 37, 37, 0.04)',
    '&:hover': {
      backgroundColor: uscmPalette.background.secondary,
    },
  },
  hostCont: {
    paddingLeft: '12px',
  },
  listHover: {
    padding: '14px 0px',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  apiBox: {
    marginBottom: '20px',
    boxShadow: 'none',
  },
});

const ApiDetails = [
  {
    metadata: {
      description: 'Gain the flexibility to initiate multiple global payments.',
      name: 'Payments-API',
      title: 'Payments API',
    },

    kind: 'API',
    spec: {
      type: 'onprem-apigee',
      version: 'v1',
      tag: 'Payments',
      icon: globeIcon,
    },
  },
  {
    metadata: {
      description: 'Check the processing status of a specific payment.',
      name: 'Payment-Status-API',
      title: 'Payment Status API',
    },

    kind: 'API',
    spec: {
      type: 'onprem-apigee',
      version: 'v1',
      tag: 'Payments',
      icon: currencyExchange,
    },
  },
  {
    metadata: {
      description: 'Access detailed transaction history in real-time.',
      name: 'Reporting-API',
      title: 'Reporting API',
    },

    kind: 'API',
    spec: {
      type: 'onprem-apigee',
      version: 'v1',
      tag: 'Payments',
      icon: investmentsSvg,
    },
  },
];

const HostToHostDetails = [
  {
    name: 'Overview',
    id: '1',
    path: '/',
  },
  {
    name: 'What is H2H?',
    id: '2',
    path: '/',
  },
  {
    name: 'Getting Started',
    id: '3',
    path: '/',
  },
  {
    name: 'Wire Payment Initiation H2H',
    id: '4',
    path: '/',
  },
  {
    name: 'ACH Payment Organization',
    id: '5',
    path: '/',
  },
];

const ApiAndHostToHost = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <LayoutContainer kind="fixed" hasMargins>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Box display={{ xs: 'none', lg: 'block' }} className={classes.apiBox}>
            <Box className={classes.apiCardContainer}>
              {ApiDetails.map((api: any) => {
                return (
                  <Box className={classes.apiCard} key={api.metadata.name}>
                    <Link to={`/catalog/default/api/${api.metadata.name}`}>
                      <Card
                        className={classes.cardHover}
                        data-testid="api-catalog-card"
                      >
                        <CardContent className={classes.cardContentConatiner}>
                          <Box className={classes.iconBackgrd}>
                            <img src={api.spec.icon} alt={api.metadata.name} />
                          </Box>

                          <Box>
                            <TextHeading
                              useCase={HeadingUseCase.noticeHeading}
                              headingLevel={HeadingLevel.h1}
                              className={classes.apiDetailsHeader}
                            >
                              {api.metadata.title ?? api.metadata.name}
                            </TextHeading>
                            <TextParagraph
                              useCase={ParagraphUseCase.introParagraph}
                              style={{ alignSelf: '' }}
                              className={classes.apiLabel}
                            >
                              {api.metadata.description}
                            </TextParagraph>
                          </Box>
                        </CardContent>
                      </Card>
                    </Link>
                  </Box>
                );
              })}
              <Box
                className={classes.apiCard}
                key="Payment-Notification-Service"
              >
                {/* commenting this link for future use */}
                {/* <Link to={`/rbc-api-catalog`}> */}
                <Card
                  className={classes.cardHover}
                  data-testid="api-catalog-card"
                >
                  <CardContent className={classes.cardContentConatiner}>
                    <Box className={classes.iconBackgrd}>
                      <img
                        src={errorIconSvg}
                        alt="Payment Notification Services"
                      />
                    </Box>

                    <Box>
                      <TextHeading
                        useCase={HeadingUseCase.noticeHeading}
                        headingLevel={HeadingLevel.h1}
                        className={classes.apiDetailsHeader}
                      >
                        Payment Notification Service
                      </TextHeading>
                      <TextParagraph
                        useCase={ParagraphUseCase.introParagraph}
                        style={{ alignSelf: '' }}
                        className={classes.apiLabel}
                      >
                        Sends push notification to your API (Coming Soon).
                      </TextParagraph>
                    </Box>
                  </CardContent>
                </Card>
                {/* </Link> */}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <InfoCardWithButton
            heading="APIs"
            subHeading="Browse our API catalog to discover our suite of APIs."
            body="Application programming interfaces (APIs) provide a way to connect apps and software systems simply, seamlessly and in real time. RBC transaction Banking APIs allow clients to connect with business services and capabilities outside of traditional banking channels for new ways to transform your business model."
            btnLabel="View Catalog"
            onClick={() => navigate('/rbc-api-catalog')}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Box className={classes.hostCont}>
            <InfoCardWithButton
              heading="Host-to-Host"
              subHeading="Learn more about our Host-to-Host offering."
              body="Our global host-to-host service is a system interface between your company and the bank designed to make your remittance operations more efficient. For large-volume transactions, our payment factory service allows for an efficient and easy workflow."
              btnLabel="Discover"
              onClick={() =>
                navigate(
                  '/docs/default/component/documentation-hub/Developer-guide/H2H-Reference-Technology/',
                )
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Box
            className={classes.hostDetsContainer}
            display={{ xs: 'none', lg: 'block' }}
          >
            {HostToHostDetails.map((hostDets: any) => {
              return (
                <List className={classes.listHover} key={hostDets.id}>
                  <li className={classes.hostItems} tabIndex={-1}>
                    <CTAText
                      elementType="button"
                      onClick={() =>
                        navigate(
                          '/docs/default/component/documentation-hub/Developer-guide/H2H-Reference-Technology/',
                        )
                      }
                      srLabel="Button for reading more information"
                    >
                      {hostDets.name}
                    </CTAText>
                  </li>
                </List>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </LayoutContainer>
  );
};

export default ApiAndHostToHost;
