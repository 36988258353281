import { createPlugin } from '@backstage/core-plugin-api';

import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';

import { rootRouteRef } from './routes';
import { NavTechdocAddonComponent } from './components/NavigationAddons/NavigationAddons';

export const navigationAddonPlugin = createPlugin({
  id: 'navigation-addon',
  routes: {
    root: rootRouteRef,
  },
});

export const NavigationAddonPage = navigationAddonPlugin.provide(
  createTechDocsAddonExtension<any>({
    name: 'NavTechdocAddon',
    location: TechDocsAddonLocations.PrimarySidebar,
    component: NavTechdocAddonComponent,
  }),
);
