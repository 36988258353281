import { LayoutBlock, Sitemap, rbcColors } from '@internal/shared';
import React from 'react';
import FeaturedApis from './FeaturedApis/FeaturedApis';
import GettingStartedBlock from './GettingStartedBlock/GettingStartedBlock';
import LinesOfBusiness from './LinesOfBusiness/LinesOfBusiness';
import SignUpSection from './SignUp/SignUpSection';
import UseCasesSection from './UseCases/UseCasesSection';
import WelcomeBanner from './WelcomeBanner/WelcomeBanner';

const RbcHomePage = () => {
  return (
    <>
      <LayoutBlock paddingYDesktop={40}>
        <WelcomeBanner />
      </LayoutBlock>

      <LayoutBlock alternateBackground>
        <LinesOfBusiness />
      </LayoutBlock>

      <LayoutBlock>
        <GettingStartedBlock />
      </LayoutBlock>

      <LayoutBlock alternateBackground>
        <FeaturedApis />
      </LayoutBlock>

      <LayoutBlock>
        <UseCasesSection />
      </LayoutBlock>
      <LayoutBlock paddingYDesktop={0} paddingYMobile={0} alternateBackground>
        <SignUpSection />
      </LayoutBlock>
      <LayoutBlock
        paddingYDesktop={64}
        backgroundColor={rbcColors.rbcBlueTint1}
      >
        <Sitemap />
      </LayoutBlock>
    </>
  );
};

export default RbcHomePage;
