/* eslint-disable no-console */
import { useApi } from '@backstage/core-plugin-api';
import { TaplyticsApiRef } from '../api/TaplyticsApi';
import { useCallback, useEffect, useState } from 'react';
import { useSharedStore } from '@internal/shared';
import { useShallow } from 'zustand/react/shallow';

const MOCK_FEATURE_FLAGS = 'mockFeatureFlags';

export const useTaplytics = () => {
  const taplyticsApi = useApi(TaplyticsApiRef);
  const setFeatureFlags = useSharedStore(store => store.setFeatureFlags);
  const featureFlags = useSharedStore(
    useShallow(store => store.featureFlags.data),
  );
  const loaded = useSharedStore(store => store.featureFlags.loaded);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    const localMock = localStorage.getItem(MOCK_FEATURE_FLAGS);
    if (localMock) {
      setFeatureFlags(localMock ? JSON.parse(localMock) : {});
      setLoading(false);
      return;
    }

    if (loaded) {
      setLoading(false);
      return;
    }

    taplyticsApi
      .getFeatureFlags()
      .then(flags => {
        setFeatureFlags(flags);
      })
      .catch(err => {
        console.error('Failed to load feature flags', err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loaded, setFeatureFlags, setLoading, taplyticsApi]);

  const isFlagEnabled = useCallback(
    (featureName: string) => {
      if (!loaded || !featureFlags || error) return false;
      return Object.values(featureFlags).includes(featureName);
    },
    [loaded, featureFlags, error],
  );

  const getVariable = useCallback(
    (variable: string, defaultValue: string | number | boolean | object) => {
      if (!loaded) return defaultValue;
      return taplyticsApi.getVariable(variable, defaultValue);
    },
    [loaded, taplyticsApi],
  );

  return { featureFlags, loading, loaded, error, getVariable, isFlagEnabled };
};
