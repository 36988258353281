export enum LOB {
  BFS = 'bfs',
  RBC = 'rbc',
  RB = 'rb',
  USCM = 'uscm',
}

export const LOBFullName = {
  [LOB.BFS]: 'Business Financial Services',
  [LOB.RBC]: 'RBC Royal Bank',
  [LOB.RB]: 'Retail Banking',
  [LOB.USCM]: 'U.S. Cash Management',
};
