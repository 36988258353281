import { LOB, bfsColors, rbcColors, useSharedStore } from '@internal/shared';
import React from 'react';
import rbcPayEdgeLogo from '../../../assets/pay-edge.png';
import LineOfBusinessAppBar from './LineOfBusiness/LineOfBusinessAppBar';
import RbcHomeAppBar, { RbcHomeAppBarProps } from './RbcHome/RbcHomeAppBar';

const lobProps: { [key in LOB]?: RbcHomeAppBarProps } = {
  [LOB.BFS]: {
    primaryColor: bfsColors.bfsBlue,
    secondaryColor: rbcColors.white,
    whiteLogo: true,
    centerImage: rbcPayEdgeLogo,
  },
};

const AppBar = () => {
  const { currentLob } = useSharedStore();
  const appBarProps = lobProps[currentLob];

  return (
    <>
      {currentLob === LOB.USCM ? (
        <LineOfBusinessAppBar />
      ) : (
        <RbcHomeAppBar {...appBarProps} />
      )}
    </>
  );
};

export default AppBar;
