import {
  PageTheme,
  SupportedThemes,
  UnifiedThemeOptions,
  createUnifiedTheme,
  palettes,
  themes,
} from '@backstage/theme';
import { rbcColors, uscmColors, uscmPalette } from '@internal/shared';

const currTheme = themes.light.getTheme('v5') as SupportedThemes;

const singlePageTheme: PageTheme = {
  colors: [rbcColors.darkBlueTint1],
  shape: '',
  backgroundImage: 'none',
  fontColor: rbcColors.darkBlueTint1,
};

export const baseThemeOptions: UnifiedThemeOptions = {
  palette: {
    ...palettes.light,
    primary: {
      ...uscmPalette.primary,
    },
    secondary: {
      ...uscmPalette.secondary,
    },
    grey: {
      50: '#C1C7D0',
      100: '#7A869A',
      200: '#6B778C',
      300: '#5E6C84',
      400: '#505F79',
      500: '#42526E',
      600: '#344563',
      700: '#253858',
      800: '#172B4D',
      900: '#091E42',
    },
    error: {
      ...uscmPalette.error,
    },
    warning: {
      ...uscmPalette.warning,
    },
    success: {
      ...uscmPalette.success,
    },
    info: {
      ...uscmPalette.info,
    },
    text: {
      primary: uscmPalette.text.dark,
    },
    background: {
      default: uscmPalette.background.main,
    },
    navigation: {
      ...currTheme?.palette.navigation,
      background: uscmPalette.background.main,
      color: uscmPalette.primary.main,
      indicator: uscmPalette.primary.main,
      navItem: {
        hoverBackground: uscmPalette.secondary.main,
      },
      submenu: {
        background: uscmPalette.background.secondary,
      },
      selectedColor: uscmPalette.primary.main,
    },
  },
  fontFamily: 'Roboto, sans-serif',
  defaultPageTheme: 'home',
  pageTheme: {
    home: singlePageTheme,
    documentation: singlePageTheme,
    tool: singlePageTheme,
    service: singlePageTheme,
    website: singlePageTheme,
    library: singlePageTheme,
    other: singlePageTheme,
    app: singlePageTheme,
    apis: singlePageTheme,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          display: 'none',
        },
        standardError: {
          color: rbcColors.white,
          backgroundColor: uscmPalette.error.main,
          '& $icon': {
            color: rbcColors.white,
          },
        },
        standardInfo: {
          color: rbcColors.white,
          backgroundColor: uscmPalette.info.main,
          '& $icon': {
            color: rbcColors.white,
          },
        },
        standardSuccess: {
          color: rbcColors.white,
          backgroundColor: uscmPalette.success.main,
          '& $icon': {
            color: rbcColors.white,
          },
        },
        standardWarning: {
          color: rbcColors.white,
          backgroundColor: uscmPalette.warning.main,
          '& $icon': {
            color: rbcColors.white,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&[aria-expanded=true]': {
            color: rbcColors.white,
          },
          '&[aria-expanded=true] path': {
            fill: rbcColors.white,
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9,30,69,0.54)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          backgroundColor: currTheme.palette.grey[50],
          color: currTheme.palette.primary.dark,
          margin: 4,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&[aria-expanded]': {
            backgroundColor: uscmColors.uscmBlueTint1,
            color: rbcColors.white,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: rbcColors.white,
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    BackstageContent: {
      styleOverrides: {
        root: {
          paddingTop: '0',
          paddingBottom: '0',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          display: 'none !important',
        },
      },
    },
  },
};

export const devportalThemeV2 = createUnifiedTheme(baseThemeOptions);
