import { useEffect, useRef, useState } from 'react';

const DEFAULT_TIMEOUT = 600;
/**
 * Session timeout hook, the default initial time is 600 seconds
 * @param props.initialTime in seconds
 * @param props.fn callback that will be invoked when the time reaches 0
 */
export const useSessionTimeout = ({
  initialTime = DEFAULT_TIMEOUT,
  fn,
}: {
  initialTime?: number;
  fn?: () => void | Promise<void>;
}) => {
  const [remainingTime, setRemainingTime] = useState(initialTime);
  const timeValueRef = useRef(initialTime);
  const timerRef = useRef<NodeJS.Timeout>();

  const refreshSession = () => {
    timeValueRef.current = initialTime;
    setRemainingTime(initialTime);
  };

  useEffect(() => {
    timerRef.current = setInterval(() => {
      timeValueRef.current = timeValueRef.current - 1;
      if (timeValueRef.current <= 60) {
        setRemainingTime(timeValueRef.current);
      }
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (remainingTime <= 0) {
      if (fn) {
        fn();
      }
      clearInterval(timerRef.current);
    }
  }, [fn, remainingTime]);

  return {
    remainingTime,
    refreshSession,
  };
};
