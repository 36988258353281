import type { Destination } from '@rig/common';
import { LOB } from '../../models';

type MenuBarLabels = {
  [key: string]: Destination[];
};

const sharedLabels: Destination[] = [
  {
    label: 'API Catalog',
    src: '/lob-catalog',
    destinationId: 'lob-catalog',
    type: 'router-link',
  },
  {
    label: 'Use Cases',
    src: '/',
    destinationId: 'use-cases',
    type: 'router-link',
  },
  {
    label: 'Resources',
    src: '/resources',
    destinationId: 'resources',
    type: 'router-link',
  },
];

export const lobLabels: MenuBarLabels = {
  [LOB.RBC]: [
    {
      label: 'Home',
      src: '/',
      destinationId: 'home',
      type: 'router-link',
    },
    ...sharedLabels,
  ],
  [LOB.BFS]: [
    {
      label: 'Home',
      src: '/bfs',
      destinationId: 'home',
      type: 'router-link',
    },
    ...sharedLabels,
  ],
  [LOB.USCM]: [
    {
      label: 'Home',
      src: '/uscm',
      destinationId: 'home',
      type: 'router-link',
    },
    {
      label: 'Catalog',
      src: '/uscm/rbc-api-catalog',
      destinationId: 'rbc-api-catalog',
      type: 'router-link',
    },
    {
      label: 'Resources',
      src: '/uscm/docs/default/component/uscm-resources/',
      destinationId: 'docs',
      type: 'router-link',
    },
  ],
};
