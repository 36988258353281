import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { OpenApiDefinitionWidget } from '@backstage/plugin-api-docs';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ApiEntity } from '@backstage/catalog-model';

const useStyles = makeStyles({
  container: {
    marginTop: '-50px',
    background: 'white',
    padding: '24px',
  },
});

export const ApiDefinitionComponent = () => {
  const classes = useStyles();

  const { entity } = useEntity<ApiEntity>();

  const definition = entity.spec.definition;
  return (
    <Box className={classes.container}>
      <OpenApiDefinitionWidget definition={definition} />
    </Box>
  );
};
